import { Button } from "@mui/material";
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';

function FeatureBasedMethodButton(props) {

    const { active, onClick } = props;

    return (
        <Button
            variant={active ? "contained" : "outlined"}
            color="warning"
            onClick={onClick}
            type='reset'
        >
            <PlaylistAddCheckIcon />
        </Button>
    );
};

export default FeatureBasedMethodButton;