import { useSelector, useDispatch } from 'react-redux';
import {
    updatePart,
    getActivePart,
    closeLoadingErrorDialog,
    getPartUploadState
} from '../../store/slices/partsSlice';
import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as yup from 'yup';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CostSettingsGroup from '../../components/buttons/CostSettingsGroup';
import SaveButton from '../../components/buttons/SaveButton';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import EstimatedCostTip from '../../components/tips/EstimatedCostTip';
import ResetButton from '../../components/buttons/ResetButton';


let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const costSchema = yup.object().shape({
    batch_sizes: yup.array()
        .of(
            yup.object().shape({
                batch_size: yup
                    .number()
                    .integer()
                    .typeError('form.error.number')
                    .positive('form.error.positive')
                    .required('form.error.field_required'),
                unit_cost: yup
                    .number()
                    .nullable()
                    .min(0, 'form.error.not_negative')
                    .test(
                        "title: max two decimals",
                        "form.error.max_two_decimals",
                        (val) => {
                            // eslint-disable-next-line
                            if (val != undefined) {
                                return patternTwoDigisAfterComma.test(val);
                            }
                            return true;
                        })
                    .typeError('form.error.number')
            })
        )
});

const emptyBatchSize = {
    batch_size: null,
    unit_cost: null,
    estimated_cost: null,
    valid_estimate: false,
};


function TileCost() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activePart = useSelector(getActivePart);
    const uploadState = useSelector(getPartUploadState);

    const gridTextFieldStyle = { px: '4px', py: '4px' };

    const batchSizeInputStyle = {
        input: { textAlign: "right" }
    };

    /* ------ SUBMIT ---------------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const batch_sizes = [...values.batch_sizes];

        batch_sizes.forEach((nextBatchSize, index) => {
            if (batch_sizes[index].unit_cost !== null) {
                batch_sizes[index] = {
                    ...nextBatchSize,
                    unit_cost: parseFloat(nextBatchSize.unit_cost)
                };
            }
        });

        dispatch(updatePart(
            {
                part_id: activePart.part_id,
                batch_sizes: batch_sizes,
            },
        ));
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Box mx="10px">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={activePart}
                    validationSchema={costSchema}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit, dirty }) => (
                        <Form>
                            {/* ------- HEADER ----------------------------------------------------- */}
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="20px"
                                my="10px"
                            >
                                <Typography
                                    variant="h6"
                                    color={colors.assigned.tableHeader}
                                    fontWeight="bold"
                                >
                                    {t("label.part_cost")}
                                </Typography>
                                <Box
                                    height="36px"   //  height of SaveButton
                                    display="flex"
                                    gap="10px"
                                >
                                    {dirty ? (
                                        <>
                                            <SaveButton
                                                onClick={handleSubmit}
                                                loading={uploadState.status === 'loading'}
                                                type='submit'
                                            />
                                            <ResetButton
                                                disabled={uploadState.status === 'loading'}
                                            />
                                        </>
                                    ) : (
                                        null
                                    )}
                                    <CostSettingsGroup onClick={null} />
                                </Box>
                            </Box>

                            {/* ------- LABELS ----------------------------------------------------- */}
                            <Grid container>
                                <Grid
                                    item
                                    xs={12} sm={1}
                                    sx={gridTextFieldStyle}
                                />
                                <Grid
                                    item
                                    xs={12} sm={2}
                                    sx={gridTextFieldStyle}
                                >
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <Typography variant='subtitle2'>
                                            {t('label.batch_size')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} sm={2}
                                    sx={gridTextFieldStyle}
                                >
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <Typography variant='subtitle2'>
                                            {t('label.estimated_cost')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} sm={3}
                                    sx={gridTextFieldStyle}
                                >
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <Typography variant='subtitle2'>
                                            {t('label.unit_cost')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} sm={3}
                                    sx={gridTextFieldStyle}
                                >
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                    >
                                        <Typography variant='subtitle2'>
                                            {t('label.batch_cost')}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={12} sm={1}
                                //sx={gridTextFieldStyle}
                                />
                            </Grid>

                            {/* ------- COST FORM --------------------------------------------------- */}
                            <Box
                                height='430px'  // set height order
                                display='flex'
                                flexDirection='column'
                                justifyContent='space-between'

                            >
                                <Box
                                    overflow='auto'
                                >
                                    <FieldArray
                                        name='batch_sizes'
                                        render={(arrayHelpers) => (
                                            <>
                                                {values.batch_sizes?.map((nextBatchSize, index) => {
                                                    return (
                                                        <Grid
                                                            key={index}
                                                            container
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12} sm={1}
                                                                sx={gridTextFieldStyle}
                                                            >
                                                                <Box
                                                                    height='100%'
                                                                    display='flex'
                                                                    alignItems='center'
                                                                >
                                                                    <Typography variant='subtitle2'>
                                                                        {index + 1}.
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12} sm={2}
                                                                sx={gridTextFieldStyle}
                                                            >
                                                                <TextField
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    name={`batch_sizes[${index}].batch_size`}
                                                                    value={nextBatchSize.batch_size || ''}
                                                                    size='small'
                                                                    //label={t('label.batch_size')}
                                                                    type='text'
                                                                    onBlur={handleBlur}
                                                                    onChange={(e) => {
                                                                        handleChange({
                                                                            ...e, target: {
                                                                                ...e.target,
                                                                                name: `batch_sizes[${index}].batch_size`,
                                                                                value: parseInt(e.target.value)
                                                                            }
                                                                        });
                                                                    }}
                                                                    error={
                                                                        Boolean(getIn(touched, `batch_sizes[${index}].batch_size`))
                                                                        && Boolean(getIn(errors, `batch_sizes[${index}].batch_size`))
                                                                    }
                                                                    helperText={
                                                                        getIn(touched, `batch_sizes[${index}].batch_size`)
                                                                        && t(getIn(errors, `batch_sizes[${index}].batch_size`))
                                                                    }
                                                                    sx={[
                                                                        batchSizeInputStyle,
                                                                        // {
                                                                        //     '& fieldset': {
                                                                        //         border: activePart.batch_sizes[index] ? 'none' : 'auto'
                                                                        //     },
                                                                        // }
                                                                    ]}
                                                                // inputProps={{ readOnly: activePart.batch_sizes[index] ? true : false }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12} sm={2}
                                                                sx={gridTextFieldStyle}
                                                            >
                                                                <EstimatedCostTip
                                                                    value={nextBatchSize.estimated_cost}
                                                                    valid={nextBatchSize.valid_estimate}
                                                                    passed={nextBatchSize.unit_cost === nextBatchSize.estimated_cost}
                                                                    onPassClick={(e) => {
                                                                        handleChange({
                                                                            ...e, target: {
                                                                                ...e.target,
                                                                                name: `batch_sizes[${index}].unit_cost`,
                                                                                value: nextBatchSize.estimated_cost
                                                                            }
                                                                        });
                                                                    }}
                                                                    currency={activePart.currency}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12} sm={3}
                                                                sx={gridTextFieldStyle}
                                                            >
                                                                <CurrencyInput
                                                                    name={`batch_sizes[${index}].unit_cost`}
                                                                    value={nextBatchSize.unit_cost}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    error={
                                                                        Boolean(getIn(touched, `batch_sizes[${index}].unit_cost`))
                                                                        && Boolean(getIn(errors, `batch_sizes[${index}].unit_cost`))
                                                                    }
                                                                    helperText={
                                                                        getIn(touched, `batch_sizes[${index}].unit_cost`)
                                                                        && t(getIn(errors, `batch_sizes[${index}].unit_cost`))
                                                                    }
                                                                    currency={activePart.currency}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12} sm={3}
                                                                sx={gridTextFieldStyle}
                                                            >
                                                                <CurrencyInput
                                                                    value={nextBatchSize.batch_size * nextBatchSize.unit_cost}
                                                                    currency={activePart.currency}
                                                                    readOnly={true}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12} sm={1}
                                                                sx={gridTextFieldStyle}
                                                            >
                                                                <Button
                                                                    variant='outlined'
                                                                    color='error'
                                                                    sx={{ minWidth: false, width: '100%', height: '100%' }}
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <ClearOutlinedIcon />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                                <Box
                                                    display='flex'
                                                    justifyContent='center'
                                                >
                                                    <Button
                                                        variant='outlined'
                                                        color='secondary'
                                                        sx={{
                                                            width: 'calc(100% - 8px)',
                                                            border: '1px dashed',
                                                            py: '7px',
                                                            my: '4px',
                                                        }}
                                                        onClick={() => arrayHelpers.push(emptyBatchSize)}
                                                    >
                                                        {t('button.add_next_batch_size')}
                                                    </Button>
                                                </Box>
                                            </>
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default TileCost;