import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

function RequestMaButton(props) {

    const { onClick, loading, disabled } = props

    const { t } = useTranslation();

    return (
        <LoadingButton
            variant="outlined"
            color="warning"
            onClick={onClick}
            loading={loading}
            //type='submit'
            disabled={disabled}
            sx={{ paddingX: '50px', /*paddingY: '2px'*/ }}
            startIcon={<RocketLaunchIcon /*sx={{ height: '30px', width: '30px' }}*/ />}
        >
            <span>{t('button.request_ma')}</span>    {/* span tags due to muiLab error */}
        </LoadingButton>
    )
}

export default RequestMaButton;