import { Box, Drawer, IconButton, useTheme } from '@mui/material';
import { tokens } from '../../theme';
// import ImesLogo from '../../assets/logos/ImesLogo';
import LoggedUser from '../bars/LoggedUser';
import ThemeToggler from '../switches/ThemeToggler';
import LanguageSwitch from '../switches/LanguageSwitch';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


export default function MobileMenuDrawer(props) {

    const { open, onClose } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { background: colors.primary[800] }
            }}

        >
            <Box
                height='100%'
                width='250px'
                display='flex' flexDirection='column'
                alignItems='center' justifyContent='space-between'

                gap='50px'
            >
                <Box
                    display='flex' flexDirection='column'
                    width='100%'
                    gap='50px'
                >
                    <Box
                        display='flex' justifyContent='flex-end'
                        padding='12px'
                    >
                        <IconButton
                            color="primary"
                            onClick={onClose}
                        >
                            <CloseRoundedIcon sx={{ fontSize: '40px', color: colors.grey[100] }} />
                        </IconButton>
                    </Box>
                    <LoggedUser />
                </Box>
                <Box
                    display='flex' flexDirection='column' alignItems='center'
                    width='100%'
                    gap='40px' pb='60px'
                >
                    <Box display='flex' justifyContent='center'
                        width='100%' gap='30px'>
                        <ThemeToggler />
                        <LanguageSwitch />
                    </Box>
                    {/* <ImesLogo scale={0.8} color={colors.grey[100]} /> */}
                </Box>
            </Box>
        </Drawer>
    );
}