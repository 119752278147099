import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPart } from '../../store/slices/partsSlice';
import { alpha, Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';
import TilePartInfo from './TilePartInfo';
import TileCost from './TileCost';
import TileEstimator from './TileEstimator';


const gridTemplateLargeScreen =
    `
    "i i e e e e p p p"
    "i i e e e e p p p"
    ". . e e e e . . ."
    `

const gridTemplateMobileScreen =
    `
    "i"
    "i"
    "p"
    "p"
    "e"
    "e"
    "e"
    `

function PagePart() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { id } = useParams();

    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY);

    const areaStyle = {
        backgroundColor: alpha(colors.assigned.backgroundSingle, 0.95),
        border: '1px solid',
        borderColor: colors.primary[400],
        borderRadius: '10px',
        boxShadow: '3px 3px 10px -5px rgba(0, 0, 0, .8)'
    };

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchPart(id));

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Box
                display='grid'
                columnGap='20px'
                rowGap={isAboveMediumScreens ? '0px' : '20px'} // the same length of columns - temporary
                sx={isAboveMediumScreens ? {
                    gridTemplateColumns: "repeat(9, minmax(150px, 1fr))",
                    gridTemplateRows: "auto",
                    gridTemplateAreas: gridTemplateLargeScreen,
                } : {
                    gridAutoColumns: "100%",
                    gridAutoRows: "auto",
                    gridTemplateAreas: gridTemplateMobileScreen,
                }}
            >
                <Box gridArea='i' sx={areaStyle}>
                    <TilePartInfo />
                </Box>
                <Box gridArea='e' sx={areaStyle}>
                    <TileEstimator />
                </Box>
                <Box gridArea='p' sx={areaStyle}>
                    <TileCost />
                </Box>
            </Box>
            <SnackbarBasic />
        </>
    );
};

export default PagePart;