import { useSelector, useDispatch } from 'react-redux';
import {
    createPart,
    deletePart,
    closeDeletingDialog,
    getActivePart,
    getPartDeleteState,
} from '../../store/slices/partsSlice';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../theme';
import { Box, useTheme } from '@mui/material';
import TableHeader from '../../components/table/TableHeader';
import GridParts from './GridParts';
import ConfirmActionDialog from '../../components/dialogs/ConfirmActionDialog';
import AddToListButton from '../../components/buttons/AddToListButton';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';


function PageParts() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activePart = useSelector(getActivePart);
    const deleteState = useSelector(getPartDeleteState);

    const handleAddNewClick = () => {

        const newPart = {
            part_name: "New Part",
            part_number: "",
            drawing_number: "",
            customer: "",
            description: "",
            currency: "€",
            batch_sizes: [{
                batch_size: 1,
                unit_cost: null,
                estimated_cost: null,
                valid_estimate: false
            }],
            material_data: {
                material: null,
                material_comment: ""
            },
            technology_data: [],
        };

        dispatch(createPart(newPart));
    };

    const handleDelete = () => {
        dispatch(deletePart(activePart));
    };

    const handleCloseDeletingDialog = () => {
        dispatch(closeDeletingDialog());
    };


    return (
        <>

            {/* PAGE */}
            <Box display='flex' flexDirection='column'>
                <TableHeader
                    title={t('page.parts')}
                    icon={<TableViewOutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    <AddToListButton
                        text={t('button.add_new_part')}
                        onClick={handleAddNewClick}
                        permission={true}
                    />
                </TableHeader>
                <GridParts />
            </Box>
            <SnackbarBasic />

            {/* DELETE DIALOG */}
            <ConfirmActionDialog
                open={deleteState.dialog}
                onConfirm={handleDelete}
                onCancel={handleCloseDeletingDialog}
                confirmButtonLabel={t('button.delete')}
                message={t('notification.confirm_delete')}
                icon={<DeleteForeverOutlinedIcon sx={{ color: colors.redAccent[500], fontSize: '140px' }} />}
                status={deleteState.status}
                error={deleteState.error}
            />
        </>
    );
};

export default PageParts;