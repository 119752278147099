import { Button } from '@mui/material';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

function ResetButton(props) {

    const { onClick, disabled } = props

    return (
        <Button
            variant="outlined"
            color="info"
            onClick={onClick}
            disabled={disabled}
            type='reset'
            sx={{ minWidth: 0, p: '5px' }}
        >
            <UndoOutlinedIcon />
        </Button>
    )
}

export default ResetButton;