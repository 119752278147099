import { Grid } from "@mui/material";
import settings from '../../../config.json';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import EstimatorAccordion from "../../../components/accordions/EstimatorAccordion";
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';


function OtherEstimator(props) {

    const { index, nextProcess, onDelete, formik } = props;

    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);


    return (
        <EstimatorAccordion
            index={index}
            nextProcess={nextProcess}
            icon={<AbcOutlinedIcon color="secondary" />}
            processLabel={t('estimator.other')}
            onDelete={onDelete}
            formik={formik}
        >
            <Grid
                container
                height={isAboveMobileSize ? 'auto' : 'auto'}
                maxWidth='600px'
                p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 0 0'}
                overflow='auto'
            >
                {/* <TextField
                        fullWidth
                        variant='outlined'
                        type='text'
                        size="small"
                        label={t('label.technology')}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.formTechnology.name || ''}
                        name='formTechnology.name'
                        error={!!formik.touched.formTechnology?.name && !!formik.errors.formTechnology?.name}
                        helperText={formik.touched.formTechnology?.name && <>{t(formik.errors.formTechnology?.name)}</>}
                    /> */}
            </Grid>
        </EstimatorAccordion>
    );
};

export default OtherEstimator;