import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';


function EditSubmitButtonGroup(props) {

    const { editMode, onSubmit, onReset, onEdit, onEditOff, loading } = props;

    return (
        editMode === false ? (
            <Button
                variant='outlined'
                color='info'
                onClick={onEdit}
                style={{ minWidth: '0px', padding: '2px' }}
            >
                <EditOutlinedIcon sx={{ fontSize: '20px' }} />
            </Button >
        ) : (
            <Box
                display='flex'
                gap='10px'
            >
                <LoadingButton
                    variant='contained'
                    color='info'
                    type='submit'
                    loading={loading}
                    onClick={onSubmit}
                    style={{ minWidth: '0px', padding: '2px 12px' }}
                >
                    <SaveOutlinedIcon sx={{ fontSize: '20px' }} />
                </LoadingButton >
                <Button
                    variant='outlined'
                    color='info'
                    type='reset'
                    disabled={loading}
                    onClick={onReset}
                    style={{ minWidth: '0px', padding: '2px' }}
                >
                    <UndoOutlinedIcon sx={{ fontSize: '20px' }} />
                </Button >
                <Button
                    variant='outlined'
                    color='info'
                    disabled={loading}
                    onClick={onEditOff}
                    style={{ minWidth: '0px', padding: '2px' }}
                >
                    <EditOffOutlinedIcon sx={{ fontSize: '20px' }} />
                </Button >
            </Box>
        )
    );
};

export default EditSubmitButtonGroup;