import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FormLogin from "./FormLogin";
import LanguageSwitch from "../../components/switches/LanguageSwitch";
import backgroundImage from "../../assets/background/login_page.jpg"
// import ImesLogo from '../../assets/logos/ImesLogo';


const PageLogin = () => {

    const { t } = useTranslation()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate();

    return (
        <Box
            height="100vh"
            display="flex"
            flexDirection='column'
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPositionX: '50%',
                backgroundPositionY: '30%'
            }}
        >
            {/* <Box
                position='fixed'
                top='30px'
                left='30px'
            >
                <ImesLogo scale={1} color='#e0e0e0' />
            </Box> */}
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p="30px"
                borderRadius="6px"
                sx={{ backgroundColor: colors.primary[400] }}
            >
                <Typography variant='h3' color={colors.grey[100]}>
                    {t('header.login')}
                </Typography>
                <FormLogin />
                <Button
                    fullWidth
                    variant="text"
                    color='primary'
                    size='medium'
                    onClick={() => { navigate('/register') }}
                    sx={{ mt: '20px' }}
                >
                    {t('button.go_to_signup')}
                </Button>
                <LanguageSwitch />
            </Box>
        </Box >
    );
};

export default PageLogin;
