import { Box, Button, TextField, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

function EstimatedCostTip(props) {

    const { value, valid, passed, onPassClick, currency } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const textFieldInputProps = {
        readOnly: true,
        style: {
            color: valid ? colors.warning : colors.redAccent[400],
            fontSize: '13px',
            textAlign: 'right',
            padding: 0,
        },
    };

    return (
        value !== null ? (
            <Box
                height="100%"
                display='flex'
                justifyContent='space-between'
                gap='6px'
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                >
                    <TextField
                        value={currency + " " + value}
                        sx={{
                            '& fieldset': { border: 'none' },
                        }}
                        inputProps={textFieldInputProps}
                        readOnly
                    />
                </Box>
                <Button
                    variant='outlined'
                    onClick={onPassClick}
                    sx={{
                        color: valid ? colors.warning : colors.redAccent[400],
                        borderColor: 'transparent',
                        height: valid ? '100%' : '20px',
                        minWidth: '14px',
                        p: 0,
                        "&:hover": {
                            borderColor: valid ? colors.warning : colors.redAccent[400],
                        },
                    }}
                >
                    {passed ? "=" : <ArrowForwardIosIcon sx={{ fontSize: '12px' }} />}
                </Button>
            </Box>
        ) : (
            <Box
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
            >
                <TipsAndUpdatesIcon sx={{ color: colors.primary[400] }} />
            </Box>
        )
    );
};

export default EstimatedCostTip;