import { Button } from "@mui/material";
import PaymentsIcon from '@mui/icons-material/Payments';

function ManualMethodButton(props) {

    const { active, onClick } = props;

    return (
        <Button
            variant={active ? "contained" : "outlined"}
            color="warning"
            onClick={onClick}
            type='reset'
        >
            <PaymentsIcon />
        </Button>
    );
};

export default ManualMethodButton;