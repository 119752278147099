import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAllMaterials,
    selectAllMaterials,
    getMaterialsDownloadState
} from '../../../store/slices/materialsSlice';
import {
    getActivePart
} from '../../../store/slices/partsSlice';
import { Autocomplete, Box, CircularProgress, Divider, Grid, TextField, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useTranslation } from 'react-i18next';
import CurrencyInput from '../../../components/inputs/CurrencyInput';
import { getMaterialGroupName } from '../../../static/materialGroups';


function MaterialEstimator(props) {

    const { formik } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activePart = useSelector(getActivePart);

    const materials = useSelector(selectAllMaterials)
    const materialsDownloadState = useSelector(getMaterialsDownloadState)

    /* --------- GET ---------------------------------------------------------------------*/

    const [isFetched, setIsFetched] = useState(false)

    const fetchMaterials = () => {
        if (!isFetched) {
            dispatch(fetchAllMaterials());
            setIsFetched(true)
        };
    };

    /* -----------------------------------------------------------------------------------*/

    const gridTextFieldStyle = { px: '5px', py: '5px' };

    return (
        <Grid
            container
            px='10px'
            py='10px'
        >
            <Grid
                item
                xs={4}
                sx={gridTextFieldStyle}
            >
                <Autocomplete
                    //freeSolo
                    name='formMaterial.material'
                    onOpen={fetchMaterials}
                    options={[...materials].sort(function (a, b) {
                        return a.material_group.localeCompare(b.material_group) || a.material_name.localeCompare(b.material_name)
                    })} //  copy of materials list can be sorted; sort by group and name
                    groupBy={(option) => t(getMaterialGroupName(option.material_group))}
                    getOptionLabel={(option) => option.material_name ? option.material_name : ""}
                    onChange={(e, value) => formik.setFieldValue('formMaterial.material', value ? value : null)}
                    value={formik.values.formMaterial.material}
                    isOptionEqualToValue={(option, value) => option.material_id === value.material_id}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            type='text'
                            size='small'
                            label={t('label.material')}
                            name='formMaterial.material'
                            onBlur={formik.handleBlur}
                            error={!!formik.touched.formMaterial?.material && !!formik.errors.formMaterial?.material}
                            helperText={formik.touched.formMaterial?.material && <>{t(formik.errors.formMaterial?.material)}</>}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {materialsDownloadState.status === 'loading'
                                            ? <CircularProgress color='secondary' size={20} />
                                            : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />}
                    renderGroup={(params) => (
                        <Box key={params.key}>
                            <Typography
                                variant='subtitle1'
                                color={colors.greenAccent[500]}
                                mx='16px'
                                mt='10px'
                            >
                                {params.group}
                            </Typography>
                            <Divider variant="middle" />
                            <Typography
                                variant='h6'
                                mx='16px'
                            >
                                {params.children}
                            </Typography>
                        </Box>
                    )}
                />
            </Grid>
            <Grid
                item
                xs={5}
                sx={gridTextFieldStyle}
            >
                <TextField
                    fullWidth
                    variant='outlined'
                    type='text'
                    size="small"
                    label={t('label.material_comment')}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.formMaterial.material_comment}
                    name='formMaterial.material_comment'
                    error={!!formik.touched.formMaterial?.material_comment && !!formik.errors.formMaterial?.material_comment}
                    helperText={formik.touched.formMaterial?.material_comment && <>{t(formik.errors.formMaterial?.material_comment)}</>}
                />
            </Grid>
            <Grid
                item
                xs={3}
                sx={gridTextFieldStyle}
            >
                <CurrencyInput
                    name='formMaterial.material_cost'
                    value={formik.values.formMaterial.material_cost}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={!!formik.touched.formMaterial?.material_cost && !!formik.errors.formMaterial?.material_cost}
                    helperText={formik.touched.formMaterial?.material_cost && <>{t(formik.errors.formMaterial?.material_cost)}</>}
                    currency={activePart.currency}
                />
            </Grid>
        </Grid>
    );
};

export default MaterialEstimator;