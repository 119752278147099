import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import EditButtonGroup from "../../components/buttons/EditButtonGroup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';


function EstimatorAccordion(props) {

    const { index, nextProcess, icon, processLabel, onDelete, formik, formError = false, children } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const [isEdited, setIsEdited] = useState(false);

    const accordionStyle = {
        width: '100%',
        background: 'transparent',
        border: '1px solid',
        borderColor: formError ? colors.redAccent[700] : 'transparent',
        '&.Mui-expanded': {
            border: '1px solid',
            borderColor: colors.primary[400],
            m: 0,
        },
    };

    const textFieldStyle = {
        '& fieldset': {
            border: isEdited ? 'auto' : 'none',
        },
    };

    const textFieldInputProps = {
        readOnly: isEdited ? false : true,
        style: {
            fontSize: "14px",
            padding: "2px 8px"
        },

    };

    const handleOnEdit = (e) => {
        e.stopPropagation();    // prevent accordion from extending
        setIsEdited(true);
    };

    const handleOnEditOff = (e) => {
        e.stopPropagation();
        setIsEdited(false);
    };


    return (
        <Box
            display='flex'
            gap='5px'
            m='0px'
        >
            <Accordion
                sx={accordionStyle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        '&.Mui-focusVisible': {
                            backgroundColor: "transparent"  // no highlight of header
                        }
                    }}
                >
                    <Box
                        width="100%"
                        display='flex'
                        flexDirection={isAboveMobileSize ? 'rows' : 'column'}
                        alignItems={isAboveMobileSize ? 'center' : 'flex-start'}
                        gap='10px'
                        pr='10px'
                    >
                        <Box
                            display='flex'
                            alignItems='center'
                            gap='10px'
                        >
                            <Typography
                                variant="subtitle2"
                                width="25px"
                            >
                                {index + 1}.
                            </Typography>
                            <Box
                                width='30px'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                            >
                                {icon}
                            </Box>
                            <Typography
                                variant="subtitle2"
                                width="150px"
                                color="secondary"
                                noWrap
                            >
                                {processLabel}
                            </Typography>
                        </Box>
                        <Box
                            width={"100%"}
                            display='flex'
                            gap='3px'
                        >
                            <EditButtonGroup
                                editMode={isEdited}
                                onEdit={handleOnEdit}
                                onEditOff={handleOnEditOff}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                type='text'
                                size="small"
                                // label={t('label..........')}
                                // onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name={`formTechnology[${index}].process_label`}
                                value={nextProcess.process_label || ''}
                                // error={}
                                // helperText={}
                                sx={textFieldStyle}
                                inputProps={textFieldInputProps}
                                onClick={e => isEdited ? e.stopPropagation() : null}
                            />
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
            <Button
                variant='outlined'
                color='error'
                sx={{ minWidth: '25px', width: '25px', height: '25px', p: 0 }}
                onClick={onDelete}
            >
                <ClearOutlinedIcon fontSize="12px" />
            </Button>
        </Box>
    );
};

export default EstimatorAccordion;