import { Button } from '@mui/material';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';


function CostSettingsGroup(props) {

    const { onClick } = props;

    return (
        <Button
            disabled
            variant='outlined'
            color='info'
            onClick={onClick}
            style={{ height: '26px', minWidth: '0px', padding: '2px' }}
        >
            <SettingsRoundedIcon sx={{ fontSize: '20px' }} />
        </Button >
    );
};

export default CostSettingsGroup;