import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { useEffect } from "react";

// colors
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#ccd3d9",
          200: "#9aa6b3",
          300: "#677a8c",
          400: "#354d66",
          500: "#022140",
          600: "#021a33",
          700: "#011426",
          800: "#010d1a",
          900: "#00070d",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#fad0d0",
          200: "#f5a1a1",
          300: "#f07272",
          400: "#eb4343",
          500: "#e61414",
          600: "#b81010",
          700: "#8a0c0c",
          800: "#5c0808",
          900: "#2e0404",
        },
        violetAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        blueAccent: {
          100: "#d0e8ff",
          200: "#a1d1ff",
          300: "#71bbff",
          400: "#42a4ff",
          500: "#138dff",
          600: "#0f71cc",
          700: "#0b5599",
          800: "#083866",
          900: "#041c33",
        },
        yellowAccent: {
          100: "#fffecc",
          200: "#fffd99",
          300: "#fffd66",
          400: "#fffc33",
          500: "#fffb00",
          600: "#ccc900",
          700: "#999700",
          800: "#666400",
          900: "#333200",
        },
        actionButton: {
          green: "#4cceac",
          red: "#e61414",
          blue: "#00c3ff",
        },
        assigned: {
          primary: "#022140",
          tableHeader: "#e61414",
          tableGrey: "#525252",
          resetButton: "#2d5f5d",
          button1: "#2d5f5d",
          sidebarActive1: "#e61414",
          orderComment: "#bb7000",
          textContrast: "#ffffff",
          backgroundSingle: "#010822",
        },
        warning: "#ffa726",
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#fbfafc",
          200: "#f6f5f9",
          300: "#2f2e30",
          400: "#edecf3",
          500: "#fcfcfc",
          600: "#bab9c0",
          700: "#8c8b90",
          800: "#5d5c60",
          900: "#2f2e30",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2e0404",
          200: "#5c0808",
          300: "#8a0c0c",
          400: "#b81010",
          500: "#e61414",
          600: "#eb4343",
          700: "#f07272",
          800: "#f5a1a1",
          900: "#fad0d0",
        },
        violetAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        blueAccent: {
          100: "#041c33",
          200: "#083866",
          300: "#0b5599",
          400: "#0f71cc",
          500: "#138dff",
          600: "#42a4ff",
          700: "#71bbff",
          800: "#a1d1ff",
          900: "#d0e8ff",
        },
        yellowAccent: {
          100: "#333200",
          200: "#666400",
          300: "#999700",
          400: "#ccc900",
          500: "#fffb00",
          600: "#fffc33",
          700: "#fffd66",
          800: "#fffd99",
          900: "#fffecc",
        },
        actionButton: {
          green: "#4cceac",
          red: "#e61414",
          blue: "#00c3ff",
        },
        assigned: {
          primary: "#022140",
          tableHeader: "#4cceac",
          tableGrey: "#e0e0e0",
          resetButton: "#4cceac",
          button1: "#2d5f5d",
          sidebarActive1: "#4cceac",
          orderComment: "#ffae00",
          textContrast: "#000000",
          backgroundSingle: "#fcfcfc",
        },
        warning: "#f57c00",
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              light: colors.primary[400],
              main: colors.assigned.primary,
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
            warning: {
              main: "#ffa726",
            },
          }
        : {
            primary: {
              light: colors.primary[400],
              main: colors.assigned.primary,
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
            warning: {
              main: "#f57c00",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 14,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 18,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      subtitle1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
      subtitle2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 12,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(
    JSON.parse(window.localStorage.getItem("COLOR_THEME")) || "dark"
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => (prev === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  useEffect(() => {
    if (mode === "light") {
      window.localStorage.setItem("COLOR_THEME", JSON.stringify("light"));
    }
    if (mode === "dark") {
      window.localStorage.setItem("COLOR_THEME", JSON.stringify("dark"));
    }
  }, [mode]);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};
