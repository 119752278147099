import { useRef, useState } from "react";
import { alpha, Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import settings from '../../../config.json';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../theme';
import { FieldArray } from 'formik';
import OtherEstimator from "./OtherEstimator";
import WeldingEstimator from "./WeldingEstimator";

const TechnologyEstimator = ({ formik }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();
    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const arrayHelpersRef = useRef(null);
    const [estimator, setEstimator] = useState(undefined)

    const processes = [
        {
            estimator: "OTHER",
            process: t('estimator.other'),
            methods: {
                estimator: "OTHER",   //  required for formik at this level to assign correct schema for 'methods'
            }
        },
        {
            estimator: "WELDING",
            process: t('estimator.welding'),
            methods: {
                estimator: "WELDING",   //  required for formik at this level to assign correct schema for 'methods'
                active: "FEATURE_BASED",
                manual: {
                    process_cost: null,
                },
                time_based: {
                    setup_time: null,
                    process_time: null,
                },
                feature_based: {
                    welding_length: null,
                    material_thickness: null,
                    welding_position: "NORMAL",
                    setup_time: null,
                },
            },
        },
    ];

    const handlePushArray = () => {
        if (estimator === undefined) {
            // null --> highlited in color after clicking button
            setEstimator(null)
        }
        if ((estimator !== null) && (estimator !== undefined)) {
            const array = {
                estimator: estimator,
                process_label: "",
                //[estimator + '_methods']: processes.find(mat => mat.estimator === estimator).methods
                methods: processes.find(mat => mat.estimator === estimator).methods
            };
            arrayHelpersRef.current.push(array)
        }

    };


    return (
        <Box
            m='15px'
        >
            <FieldArray
                name='formTechnology'
                render={(arrayHelpers) => {
                    arrayHelpersRef.current = arrayHelpers;
                    return (
                        <>

                            {formik.values.formTechnology?.map((nextProcess, index) => (

                                <Box
                                    key={index}
                                    mb="6px"
                                >
                                    {nextProcess.estimator === 'OTHER' ? <OtherEstimator index={index} nextProcess={nextProcess} onDelete={() => arrayHelpers.remove(index)} formik={formik} /> : null}
                                    {nextProcess.estimator === 'WELDING' ? <WeldingEstimator index={index} nextProcess={nextProcess} onDelete={() => arrayHelpers.remove(index)} formik={formik} /> : null}
                                </Box>

                            ))}
                            <Box
                                display='flex'
                                flexDirection='column'
                                justifyContent='center'
                            >
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    sx={{
                                        position: "relative",   // relative to autocomplete
                                        width: '100%',
                                        border: '1px dashed',
                                        py: '10px',
                                        pl: isAboveMobileSize ? '272px' : '212px',
                                        my: '2px',
                                    }}
                                    onClick={handlePushArray}
                                >
                                    {t('button.add_next_process')}
                                </Button>
                                <Autocomplete
                                    disablePortal
                                    options={processes}
                                    getOptionLabel={(option) => option.process}
                                    onChange={(e, value) => { e.stopPropagation(); setEstimator(value?.estimator) }}
                                    isOptionEqualToValue={(option, value) => option.estimator === value.estimator}
                                    sx={{
                                        position: "relative",   // position to button
                                        transform: isAboveMobileSize ? 'translate(80px, -37px)' : 'translate(20px, -37px)',
                                        width: 192,
                                        pl: "10px",
                                        backgroundColor: estimator === null ? alpha(colors.redAccent[500], 0.2) : null,
                                        //borderColor: "transparent",
                                        "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot":
                                        {
                                            fontSize: "12px",
                                            color: estimator === null ? colors.redAccent[500] : colors.greenAccent[500],
                                            //borderColor: "transparent",
                                        },
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            color='secondary'
                                            size='small'
                                            placeholder={t("label.select_process")}
                                            InputProps={{ ...params.InputProps, disableUnderline: true }}
                                        />}
                                />
                            </Box>
                        </>
                    )
                }}
            />
        </Box>
    );
};

export default TechnologyEstimator;
