import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/accountSlice";
import loginReducer from "./slices/loginSlice";
import manufacturingAnalysisReducer from "./slices/manufacturingAnalysisSlice";
import materialsReducer from "./slices/materialsSlice";
import partsReducer from "./slices/partsSlice";
import registerReducer from "./slices/registerSlice";
import snackbarReducer from "./slices/snackbarSlice";
import usersReducer from "./slices/usersSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload"],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
  reducer: {
    account: accountReducer,
    login: loginReducer,
    manufacturingAnalysis: manufacturingAnalysisReducer,
    materials: materialsReducer,
    parts: partsReducer,
    register: registerReducer,
    snackbar: snackbarReducer,
    users: usersReducer,
  },
});
