import { InputAdornment, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";


function CurrencyInput(props) {

    const { name, value, error, helperText, onBlur, onChange, currency, readOnly = false } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const inputStyle = {
        input: { textAlign: "right" },
        '& .MuiOutlinedInput-root': {
            paddingLeft: 0,
        },
        '& .MuiInputAdornment-root': {
            backgroundColor: (value && (value > 0)) ? colors.greenAccent[700] : colors.redAccent[600],
            padding: '20px 10px',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
        },
    };


    return (
        <TextField
            fullWidth
            variant='outlined'
            name={name}
            value={value === 0 || value ? value : ''}
            size='small'
            type='text'
            onBlur={onBlur}
            onChange={onChange}
            error={error}
            helperText={helperText}
            InputProps={{
                readOnly: readOnly,
                startAdornment:
                    <InputAdornment position="start">
                        {currency}
                    </InputAdornment>
            }}
            sx={inputStyle}
        />
    )
}

export default CurrencyInput