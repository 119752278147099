import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import FormRegister from "./FormRegister";
import LanguageSwitch from "../../components/switches/LanguageSwitch";
import backgroundImage from "../../assets/background/register_page.jpg"
import PopoverRegister from "./PopoverRegister";
//import ImesLogo from '../../assets/logos/ImesLogo';


function PageRegister() {
    const { t } = useTranslation()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
    const navigate = useNavigate();

    return (
        <Box
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPositionX: '50%',
                backgroundPositionY: '30%'
            }}
        >
            {/* <Box
                position='fixed'
                top='30px'
                left='30px'
            >
                <ImesLogo scale={1} color='#e0e0e0' />
            </Box> */}
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p="30px"
                borderRadius="6px"
                sx={{ backgroundColor: colors.primary[400] }}
            >
                <Box
                    width='100%'
                    display='flex' justifyContent='space-between' alignItems='center'
                    pl='40px'
                >
                    <Typography variant='h3'>
                        {t('header.register')}
                    </Typography>
                    <PopoverRegister />
                </Box>
                <FormRegister />
                <Box display='flex' justifyContent='center' alignItems='center' gap='10px' width='100%' mt='20px' >
                    <Typography variant='subtitle1'>
                        {t('other.have_account')}
                    </Typography>
                    <Button
                        variant="text"
                        color='primary'
                        size='medium'
                        onClick={() => { navigate('/login') }}
                        sx={{ px: '16px' }}
                    >
                        {t('button.go_to_login')}
                    </Button>
                </Box>
                <LanguageSwitch />
            </Box>
        </Box>
    );
};

export default PageRegister