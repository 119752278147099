import { useState } from "react";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import settings from '../../../config.json';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import EstimatorAccordion from "../../../components/accordions/EstimatorAccordion";
import FlareIcon from '@mui/icons-material/Flare';
import StraightenIcon from '@mui/icons-material/Straighten';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import UpdateIcon from '@mui/icons-material/Update';
import EstimatorMethodNavigation from "../../../components/navigation/EstimatorMethodNavigation";
import ManualMethodButton from "../../../components/navigation/ManualMethodButton";
import TimeBasedMethodButton from "../../../components/navigation/TimeBasedMethodButton";
import FeatureBasedMethodButton from "../../../components/navigation/FeatureBasedMethodButton";


function WeldingEstimator(props) {

    const { index, nextProcess, onDelete, formik, } = props;

    const { t } = useTranslation();
    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const [method, setMethod] = useState(nextProcess.methods.active)

    const gridTextFieldStyle = { px: '5px', py: '5px' };

    const weldingPositions = [
        {
            position: "EASY",
            name: t('estimator.welding_position.easy'),
        },
        {
            position: "NORMAL",
            name: t('estimator.welding_position.normal'),
        },
        {
            position: "HARD",
            name: t('estimator.welding_position.hard'),
        },
    ];

    /* --------- INPUT ERRORS -------------------------------------------------------------------*/

    const e_process_cost = Boolean(getIn(formik.touched, `formTechnology[${index}].methods.manual.process_cost`))
        && Boolean(getIn(formik.errors, `formTechnology[${index}].methods.manual.process_cost`));

    const e_t_setup_time = Boolean(getIn(formik.touched, `formTechnology[${index}].methods.time_based.setup_time`))
        && Boolean(getIn(formik.errors, `formTechnology[${index}].methods.time_based.setup_time`));

    const e_process_time = Boolean(getIn(formik.touched, `formTechnology[${index}].methods.time_based.process_time`))
        && Boolean(getIn(formik.errors, `formTechnology[${index}].methods.time_based.process_time`));

    const e_welding_length = Boolean(getIn(formik.touched, `formTechnology[${index}].methods.feature_based.welding_length`))
        && Boolean(getIn(formik.errors, `formTechnology[${index}].methods.feature_based.welding_length`));

    const e_material_thickness = Boolean(getIn(formik.touched, `formTechnology[${index}].methods.feature_based.material_thickness`))
        && Boolean(getIn(formik.errors, `formTechnology[${index}].methods.feature_based.material_thickness`));

    const e_f_setup_time = Boolean(getIn(formik.touched, `formTechnology[${index}].methods.feature_based.setup_time`))
        && Boolean(getIn(formik.errors, `formTechnology[${index}].methods.feature_based.setup_time`));

    const formError = e_process_cost || e_t_setup_time || e_process_time || e_welding_length || e_material_thickness || e_f_setup_time;

    /* ------------------------------------------------------------------------------------------*/

    const handleSetMethod = (newMethod) => {
        setMethod(newMethod)
        formik.setFieldValue(`formTechnology[${index}].methods.active`, newMethod)
    };

    return (
        <EstimatorAccordion
            index={index}
            nextProcess={nextProcess}
            icon={<FlareIcon color="secondary" />}
            processLabel={t('estimator.welding')}
            onDelete={onDelete}
            formik={formik}
            formError={formError}
        >
            <EstimatorMethodNavigation>
                <ManualMethodButton
                    active={method === "MANUAL"}
                    onClick={() => handleSetMethod("MANUAL")}
                />
                <TimeBasedMethodButton
                    active={method === "TIME_BASED"}
                    onClick={() => handleSetMethod("TIME_BASED")}
                />
                <FeatureBasedMethodButton
                    active={method === "FEATURE_BASED"}
                    onClick={() => handleSetMethod("FEATURE_BASED")}
                />
            </EstimatorMethodNavigation>

            {method === "MANUAL" ? (
                <Box
                    width={isAboveMobileSize ? '50%' : '100%'}
                >
                    <Grid
                        container
                    //height={isAboveMobileSize ? 'auto' : 'auto'}
                    //maxWidth='600px'
                    //p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 0 0'}
                    //overflow='auto'
                    >
                        <Grid
                            item
                            xs={12}
                            sx={gridTextFieldStyle}
                        >
                            <TextField
                                fullWidth
                                variant='filled'
                                type='text'
                                size="small"
                                label={t('estimator.input.process_cost')}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={nextProcess.methods.manual.process_cost || ''}
                                name={`formTechnology[${index}].methods.manual.process_cost`}
                                error={e_process_cost}
                                helperText={
                                    getIn(formik.touched, `formTechnology[${index}].methods.manual.process_cost`)
                                    && t(getIn(formik.errors, `formTechnology[${index}].methods.manual.process_cost`))
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <PaymentsIcon color="warning" />
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null}

            {method === "TIME_BASED" ? (
                <Box
                    width='100%'
                >
                    <Grid
                        container
                    //height={isAboveMobileSize ? 'auto' : 'auto'}
                    //maxWidth='600px'
                    //p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 0 0'}
                    //overflow='auto'
                    >
                        <Grid
                            item
                            xs={isAboveMobileSize ? 6 : 12}
                            sx={gridTextFieldStyle}
                        >
                            <TextField
                                fullWidth
                                variant='filled'
                                type='text'
                                size="small"
                                label={t('estimator.input.setup_time')}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={nextProcess.methods.time_based.setup_time || ''}
                                name={`formTechnology[${index}].methods.time_based.setup_time`}
                                error={e_t_setup_time}
                                helperText={
                                    getIn(formik.touched, `formTechnology[${index}].methods.time_based.setup_time`)
                                    && t(getIn(formik.errors, `formTechnology[${index}].methods.time_based.setup_time`))
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <AccessTimeIcon color="warning" />
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={isAboveMobileSize ? 6 : 12}
                            sx={gridTextFieldStyle}
                        >
                            <TextField
                                fullWidth
                                variant='filled'
                                type='text'
                                size="small"
                                label={t('estimator.input.process_time')}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={nextProcess.methods.time_based.process_time || ''}
                                name={`formTechnology[${index}].methods.time_based.process_time`}
                                error={e_process_time}
                                helperText={
                                    getIn(formik.touched, `formTechnology[${index}].methods.time_based.process_time`)
                                    && t(getIn(formik.errors, `formTechnology[${index}].methods.time_based.process_time`))
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <UpdateIcon color="warning" />
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null}

            {method === "FEATURE_BASED" ? (
                <Box
                    width={isAboveMobileSize ? '50%' : '100%'}
                >
                    <Grid
                        container
                    //height={isAboveMobileSize ? 'auto' : 'auto'}
                    //maxWidth='600px'
                    //p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 0 0'}
                    //overflow='auto'
                    >
                        <Grid
                            item
                            xs={12}
                            sx={gridTextFieldStyle}
                        >
                            <TextField
                                fullWidth
                                variant='filled'
                                type='text'
                                size="small"
                                label={t('estimator.input.welding_length')}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={nextProcess.methods.feature_based.welding_length || ''}
                                name={`formTechnology[${index}].methods.feature_based.welding_length`}
                                error={e_welding_length}
                                helperText={
                                    getIn(formik.touched, `formTechnology[${index}].methods.feature_based.welding_length`)
                                    && t(getIn(formik.errors, `formTechnology[${index}].methods.feature_based.welding_length`))
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <StraightenIcon color="warning" />
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={gridTextFieldStyle}
                        >
                            <TextField
                                fullWidth
                                variant='filled'
                                type='text'
                                size="small"
                                label={t('estimator.input.material_thickness')}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={nextProcess.methods.feature_based.material_thickness || ''}
                                name={`formTechnology[${index}].methods.feature_based.material_thickness`}
                                error={e_material_thickness}
                                helperText={
                                    getIn(formik.touched, `formTechnology[${index}].methods.feature_based.material_thickness`)
                                    && t(getIn(formik.errors, `formTechnology[${index}].methods.feature_based.material_thickness`))
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <VerticalAlignCenterIcon color="warning" />
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={gridTextFieldStyle}
                        >
                            <Autocomplete
                                disableClearable    // disable clear icon
                                options={weldingPositions.map(x => x.name)}
                                filterOptions={(options) => options}    // always show all options
                                name={`formTechnology[${index}].methods.feature_based.welding_position`}
                                value={weldingPositions.find(pos => pos.position === nextProcess.methods.feature_based.welding_position)?.name}
                                onChange={(e, value) => {
                                    formik.handleChange({
                                        ...e, target: {
                                            ...e.target,
                                            name: `formTechnology[${index}].methods.feature_based.welding_position`,
                                            value: weldingPositions.find(pos => pos.name === value).position
                                        }
                                    });
                                }}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        fullWidth
                                        variant='filled'
                                        type="text"
                                        size='small'
                                        label={t('estimator.input.welding_position')}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            endAdornment:
                                                // icon:
                                                <Box style={{ marginTop: "-12px", marginRight: "-27px" }}>
                                                    {params.InputProps.endAdornment}
                                                    <ZoomOutMapIcon
                                                        color="warning"
                                                    />
                                                </Box>
                                        }}
                                        sx={{
                                            // dropdown arrow icon:
                                            "& .MuiButtonBase-root": {
                                                position: "absolute",
                                                padding: 0,
                                                right: "32px",
                                                top: "2px"
                                            },
                                        }}
                                    />}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={gridTextFieldStyle}
                        >
                            <TextField
                                fullWidth
                                variant='filled'
                                type='text'
                                size="small"
                                label={t('estimator.input.setup_time')}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={nextProcess.methods.feature_based.setup_time || ''}
                                name={`formTechnology[${index}].methods.feature_based.setup_time`}
                                error={e_f_setup_time}
                                helperText={
                                    getIn(formik.touched, `formTechnology[${index}].methods.feature_based.setup_time`)
                                    && t(getIn(formik.errors, `formTechnology[${index}].methods.feature_based.setup_time`))
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment:
                                        <TimerOutlinedIcon color="warning" />
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            ) : null}

        </EstimatorAccordion>
    );
};

export default WeldingEstimator;