import { Box } from '@mui/material';

function EstimatorMethodNavigation(props) {

    const { children } = props

    return (
        <Box
            display='flex'
            gap='5px'
            mx='5px'
            mb='5px'
        >
            {children}
        </Box>
    )
}

export default EstimatorMethodNavigation