import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';

const PublicRoute = (props) => {

    const { children } = props;
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if (auth.isAuthenticated) {
            if (window.localStorage.getItem('SIDEBAR_SELECTED_OPTION')) {
                navigate(`/${JSON.parse(window.localStorage.getItem('SIDEBAR_SELECTED_OPTION'))}`, { replace: true, state: { from: location } });
            } else {
                navigate('/parts', { replace: true, state: { from: location } });
                window.localStorage.setItem('SIDEBAR_SELECTED_OPTION', JSON.stringify('parts'));
            };
        } else {
            setIsVerified(true);
        };
    }, [auth.isAuthenticated, location, navigate])


    if (!isVerified) {
        return null;
    };
    return (
        <>{children}</>
    );
};

export default PublicRoute;