import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { setActivePartFromManufacturingAnalysisSlice } from "./partsSlice";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  request: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const requestMA = createAsyncThunk(
  "requestMA",
  async (mar, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/manufacturingAnalysis/`, mar);
      dispatch(setActivePartFromManufacturingAnalysisSlice(response.data));
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const manufacturingAnalysisSlice = createSlice({
  name: "manufacturingAnalysis",
  initialState,
  reducers: {
    closeMARequestErrorDialog: (state) => {
      state.request.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestMA.pending, (state) => {
        state.request.status = "loading";
      })
      .addCase(requestMA.fulfilled, (state) => {
        state.request.status = "succeeded";
      })
      .addCase(requestMA.rejected, (state, action) => {
        state.request.status = "failed";
        state.request.error.status = action.payload.response.status;
        state.request.error.status_text = action.payload.response.statusText;
        state.request.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- MA ------------------------------------------------------------------- */
export const getMARequestState = (state) => state.manufacturingAnalysis.request;

/* --- REDUCERS --------------------------------------- */
export const { closeMARequestErrorDialog } = manufacturingAnalysisSlice.actions;

export default manufacturingAnalysisSlice.reducer;
