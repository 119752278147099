export const materialGroups = [
  {
    material_group: "ALUMINIUM",
    group_name: "material_group.aluminium",
  },
  {
    material_group: "BRASS",
    group_name: "material_group.brass",
  },
  {
    material_group: "BRONZE",
    group_name: "material_group.bronze",
  },
  {
    material_group: "CAST_IRON",
    group_name: "material_group.cast_iron",
  },
  {
    material_group: "CERAMIC",
    group_name: "material_group.ceramic",
  },
  {
    material_group: "COMPOSITE",
    group_name: "material_group.composite",
  },
  {
    material_group: "COPPER",
    group_name: "material_group.copper",
  },
  {
    material_group: "LEAD",
    group_name: "material_group.lead",
  },
  {
    material_group: "LOW_CARBON_STEEL",
    group_name: "material_group.low_carbon_steel",
  },
  {
    material_group: "MAGNESIUM",
    group_name: "material_group.magnesium",
  },
  {
    material_group: "NICKEL",
    group_name: "material_group.nickel",
  },
  {
    material_group: "POLYMER",
    group_name: "material_group.polymer",
  },
  {
    material_group: "STAINLESS_STEEL",
    group_name: "material_group.stainless_steel",
  },
  {
    material_group: "TITANIUM",
    group_name: "material_group.titanium",
  },
  {
    material_group: "TOOL_STEEL",
    group_name: "material_group.tool_steel",
  },
  {
    material_group: "ZINC",
    group_name: "material_group.zinc",
  },
];

export function getMaterialGroupName(group) {
  return materialGroups.find((mat) => mat.material_group === group)?.group_name;
}
