import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialPart = {
  part_name: "",
  part_number: "",
  drawing_number: "",
  customer: "",
  description: "",
  currency: "€",
  batch_sizes: [
    {
      batch_size: 1,
      unit_cost: null,
      estimated_cost: null,
      valid_estimate: false,
    },
  ],
  material_data: {
    material: null,
    material_comment: "",
    material_cost: null,
  },
  technology_data: [],
};

const initialState = {
  parts: [],
  activePart: initialPart,
  // partDialog: {
  //   isOpen: false,
  //   mode: null, //  null | 'add' | 'edit'
  //   activeTab: "general",
  // },
  partInfo: {
    isEdited: false,
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const fetchAllParts = createAsyncThunk(
  "parts/fetchParts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/parts/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchPart = createAsyncThunk(
  "parts/fetchPart",
  async (part_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/parts/${part_id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createPart = createAsyncThunk(
  "parts/createPart",
  async (part, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/parts/create`, part);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updatePart = createAsyncThunk(
  "parts/updatePart",
  async (part, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/parts/${part.part_id}`, part);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deletePart = createAsyncThunk(
  "parts/deletePart",
  async (part, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/parts/${part.part_id}`);
      return part.part_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const partsSlice = createSlice({
  name: "parts",
  initialState,
  reducers: {
    /* --- Part ------------------------------------------- */
    setActivePart: (state, action) => {
      state.activePart = state.parts.find(
        (part) => part.part_id === action.payload.part_id
      );
    },
    setActivePartFromManufacturingAnalysisSlice: (state, action) => {
      state.activePart = action.payload;
      const part_index = state.parts.findIndex(
        (part) => part.part_id === action.payload.part_id
      );
      state.parts[part_index] = action.payload;
    },
    // resetActivePart: (state) => {
    //   state.activePart = initialPart;
    // },
    /* --- Part dialog -------------------------------------*/
    // startAddingPart: (state) => {
    //   state.partDialog.mode = "add";
    //   state.partDialog.activeTab = "general";
    //   state.partDialog.isOpen = true;
    // },
    // startEditingPart: (state) => {
    //   state.partDialog.mode = "edit";
    //   state.partDialog.activeTab = "general";
    //   state.partDialog.isOpen = true;
    // },
    // closePartDialog: (state) => {
    //   state.partDialog.isOpen = false;
    //   state.partDialog.activeTab = "general";
    //   state.partDialog.mode = null;
    //   state.activePart = initialPart;
    // },
    // setPartActiveTab: (state, action) => {
    //   state.partDialog.activeTab = action.payload;
    // },
    /* --- Part info ---------------------------------------*/
    startEditingPartInfo: (state) => {
      state.partInfo.isEdited = true;
    },
    finishEditingPartInfo: (state) => {
      state.partInfo.isEdited = false;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingPart: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- FETCH ALL -------------------------------------- */
      .addCase(fetchAllParts.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchAllParts.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.parts = action.payload;
      })
      .addCase(fetchAllParts.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- FETCH SINGLE ----------------------------------- */
      .addCase(fetchPart.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchPart.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.activePart = action.payload;
      })
      .addCase(fetchPart.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createPart.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createPart.fulfilled, (state, action) => {
        //state.activePart = action.payload;
        state.parts.push(action.payload);
        state.uploading.status = "succeeded";
        //state.partDialog.mode = "edit";
      })
      .addCase(createPart.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updatePart.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updatePart.fulfilled, (state, action) => {
        state.activePart = action.payload;
        const part_index = state.parts.findIndex(
          (part) => part.part_id === action.payload.part_id
        );
        state.parts[part_index] = action.payload;
        state.uploading.status = "succeeded";
      })
      .addCase(updatePart.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deletePart.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deletePart.fulfilled, (state, action) => {
        const part_index = state.parts.findIndex(
          (part) => part.part_id === action.payload
        );
        state.parts.splice(part_index, 1);
        state.activePart = initialPart;
        state.deleting.status = "succeeded";
      })
      .addCase(deletePart.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Parts ------------------------------------------------------------------- */
export const getActivePart = (state) => state.parts.activePart;
export const selectAllParts = (state) => state.parts.parts;

/* --- Part Dialog ------------------------------------------------------------- */
// export const getPartDialogState = (state) => state.parts.partDialog;

/* --- Part Info-- ------------------------------------------------------------- */
export const getPartInfoState = (state) => state.parts.partInfo;

/* --- Part Functions ---------------------------------------------------------- */
export const getPartsDownloadState = (state) => state.parts.downloading;
export const getPartUploadState = (state) => state.parts.uploading;
export const getPartDeleteState = (state) => state.parts.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActivePart,
  setActivePartFromManufacturingAnalysisSlice,
  // resetActivePart,
  // startAddingPart,
  // startEditingPart,
  // closePartDialog,
  // setPartActiveTab,
  startEditingPartInfo,
  finishEditingPartInfo,
  startDeletingPart,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = partsSlice.actions;

export default partsSlice.reducer;
