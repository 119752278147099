import { Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';


function EditButtonGroup(props) {

    const { editMode, onEdit, onEditOff } = props;

    const buttonStyle = {
        minWidth: false,
        height: '20px',
        width: '20px',
        border: 0,
        padding: 0,
        '&:hover': {
            border: 0
        },
    };

    return (
        editMode === false ? (
            <Button
                variant='outlined'
                color='info'
                onClick={onEdit}
                sx={buttonStyle}
            >
                <EditOutlinedIcon sx={{ fontSize: '12px' }} />
            </Button >
        ) : (
            <Button
                variant='outlined'
                color='info'
                onClick={onEditOff}
                sx={buttonStyle}
            >
                <EditOffOutlinedIcon sx={{ fontSize: '12px' }} />
            </Button >
        )
    );
};

export default EditButtonGroup;