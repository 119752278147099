import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    requestMA,
    getMARequestState,
    closeMARequestErrorDialog
} from '../../store/slices/manufacturingAnalysisSlice';
import {
    updatePart,
    getActivePart,
    closeLoadingErrorDialog,
    getPartUploadState
} from '../../store/slices/partsSlice';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import MaterialEstimator from './estimator/MaterialEstimator';
import TechnologyEstimator from './estimator/TechnologyEstimator';
import DividerLeftLabel from '../../components/dialogs/DividerLeftLabel';
import RequestMaButton from '../../components/buttons/RequestMaButton';
import SaveButton from '../../components/buttons/SaveButton';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';



let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

function TileEstimator() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [requestType, setRequestType] = useState(undefined)
    const [requestTrigger, setRequestTrigger] = useState(true)

    const activePart = useSelector(getActivePart);
    const uploadState = useSelector(getPartUploadState);

    const MARequestState = useSelector(getMARequestState);

    /* --------- FORMIK -------------------------------------------------------------------*/

    const otherSchema = yup.object(null)

    const weldingSchema = yup.object({
        manual: yup.object({
            process_cost: yup
                .number()
                .nullable()
                .min(0, 'form.error.not_negative')
                .test(
                    "title: max two decimals",
                    "form.error.max_two_decimals",
                    (val) => {
                        if (val !== undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    })
                .typeError('form.error.number')
                .when('_', {
                    is: () => requestType === "ma",
                    then: (schema) => schema.required('form.error.field_required'),
                    otherwise: (schema) => schema
                })
        })
            .when('active', {
                is: (value) => value === "MANUAL",
                then: (schema) => schema,
                otherwise: () => yup.object()
            }),
        time_based: yup.object({
            setup_time: yup
                .number()
                .nullable()
                .positive('form.error.positive')
                .test(
                    "title: max two decimals",
                    "form.error.max_two_decimals",
                    (val) => {
                        if (val !== undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    })
                .typeError('form.error.number')
                .when('_', {
                    is: () => requestType === "ma",
                    then: (schema) => schema.required('form.error.field_required'),
                    otherwise: (schema) => schema
                }),
            process_time: yup
                .number()
                .nullable()
                .positive('form.error.positive')
                .test(
                    "title: max two decimals",
                    "form.error.max_two_decimals",
                    (val) => {
                        if (val !== undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    })
                .typeError('form.error.number')
                .when('_', {
                    is: () => requestType === "ma",
                    then: (schema) => schema.required('form.error.field_required'),
                    otherwise: (schema) => schema
                }),
        })
            .when('active', {
                is: (value) => value === "TIME_BASED",
                then: (schema) => schema,
                otherwise: () => yup.object()
            }),
        feature_based: yup.object({
            welding_length: yup
                .number()
                .nullable()
                .positive('form.error.positive')
                .test(
                    "title: max two decimals",
                    "form.error.max_two_decimals",
                    (val) => {
                        if (val !== undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    })
                .typeError('form.error.number')
                .when('_', {
                    is: () => requestType === "ma",
                    then: (schema) => schema.required('form.error.field_required'),
                    otherwise: (schema) => schema
                }),
            material_thickness: yup
                .number()
                .nullable()
                .positive('form.error.positive')
                .test(
                    "title: max two decimals",
                    "form.error.max_two_decimals",
                    (val) => {
                        if (val !== undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    })
                .typeError('form.error.number')
                .when('_', {
                    is: () => requestType === "ma",
                    then: (schema) => schema.required('form.error.field_required'),
                    otherwise: (schema) => schema
                }),
            // welding_position: yup
            //     .string()
            //     }),
            setup_time: yup
                .number()
                .nullable()
                .positive('form.error.positive')
                .test(
                    "title: max two decimals",
                    "form.error.max_two_decimals",
                    (val) => {
                        if (val !== undefined) {
                            return patternTwoDigisAfterComma.test(val);
                        }
                        return true;
                    })
                .typeError('form.error.number')
                .when('_', {
                    is: () => requestType === "ma",
                    then: (schema) => schema.required('form.error.field_required'),
                    otherwise: (schema) => schema
                }),
        })
            .when('active', {
                is: (value) => value === "FEATURE_BASED",
                then: (schema) => schema,
                otherwise: () => yup.object()
            }),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            formMaterial: {
                material: activePart.material_data.material,
                material_comment: activePart.material_data.material_comment,
                material_cost: activePart.material_data.material_cost,
            },
            formTechnology: activePart.technology_data,
        },
        validationSchema: yup.object({
            formMaterial: yup.object({
                material: yup
                    .object()
                    .nullable(),
                material_comment: yup
                    .string(),
                material_cost: yup
                    .number()
                    .nullable()
                    .min(0, 'form.error.not_negative')
                    .test(
                        "title: max two decimals",
                        "form.error.max_two_decimals",
                        (val) => {
                            // eslint-disable-next-line
                            if (val != undefined) {
                                return patternTwoDigisAfterComma.test(val);
                            }
                            return true;
                        })
                    .typeError('form.error.number')
                    .when('_', {
                        is: () => requestType === "ma",
                        then: (schema) => schema.required('form.error.field_required'),
                        otherwise: (schema) => schema
                    }),
            }),
            formTechnology: yup.array()
                .of(
                    yup.object().shape({
                        estimator: yup
                            .string()
                            .required(),
                        process_label: yup
                            .string(),
                        methods: yup.lazy((value) => {
                            if (value.estimator === "OTHER") {
                                return otherSchema
                            }
                            if (value.estimator === "WELDING") {
                                return weldingSchema
                            }
                        })
                    }),
                ),
        }),
        onSubmit: () => {
            if (requestType === "save") {

                const updateData = {
                    part_id: activePart.part_id,
                    material_data: {
                        material: formik.values.formMaterial.material?.material_id || null,
                        material_comment: formik.values.formMaterial.material_comment,
                        material_cost: (formik.values.formMaterial.material_cost)?.toString() || null,   // float sends 'null' instead of 0
                    },
                    technology_data: formik.values.formTechnology
                };
                dispatch(updatePart(updateData));
            };
            if (requestType === "ma") {

                const mar = {
                    part_id: activePart.part_id,
                };
                dispatch(requestMA(mar));

            };
        },
    });

    //  first run - waiting for requestType to update
    const isFirstRun = useRef(true);
    useEffect(() => {
        if (!isFirstRun.current) {
            formik.handleSubmit()
        }
        else {
            isFirstRun.current = false
        }
        // eslint-disable-next-line
    }, [requestTrigger])

    /* ------------------------------------------------------------------------------------*/

    return (
        <>
            <Box
                mx="10px"
            >
                <FormikProvider value={formik}>
                    <form /*onSubmit={formik.handleSubmit} --> functions used, not the button 'submit' type*/ >
                        {/* ------- HEADER ----------------------------------------------------- */}
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            //alignItems="center"
                            ml="20px"
                            mr="15px"
                            mt="10px"
                        >
                            <Typography
                                variant="h6"
                                color={colors.assigned.tableHeader}
                                fontWeight="bold"
                            >
                                {t("label.estimator")}
                            </Typography>
                            <Box
                                display="flex"
                                gap="10px"
                            >
                                {formik.dirty ? (
                                    <SaveButton
                                        onClick={() => {
                                            setRequestType("save");
                                            setRequestTrigger(!requestTrigger);
                                        }}
                                        loading={uploadState.status === 'loading'}
                                    />
                                ) : (
                                    null
                                )}
                                <RequestMaButton
                                    onClick={() => {
                                        setRequestType("ma");
                                        setRequestTrigger(!requestTrigger);
                                    }}
                                    loading={MARequestState.status === 'loading'}
                                    disabled={formik.dirty}
                                />
                            </Box>
                        </Box>

                        {/* ------- ESTIMATOR --------------------------------------------------- */}

                        <DividerLeftLabel
                            label={t("label.material")}
                        />
                        <MaterialEstimator formik={formik} />
                        <DividerLeftLabel
                            label={t("label.technology")}
                        />
                        <TechnologyEstimator formik={formik} />

                    </form>
                </FormikProvider>

                <ErrorLoadingDataDialog
                    open={uploadState.status === 'failed'}
                    error={uploadState.error}
                    onClose={() => dispatch(closeLoadingErrorDialog())}
                />

                <ErrorLoadingDataDialog
                    open={MARequestState.status === 'failed'}
                    error={MARequestState.error}
                    onClose={() => dispatch(closeMARequestErrorDialog())}
                />
            </Box>
        </>
    );
};

export default TileEstimator;