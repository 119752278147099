import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialMaterial = {
  material_name: "",
  material_group: "",
  density: "",
  description: "",
  machining_parameters: {},
  // machining_parameters: {
  //   drilling: { cutting_speed: null, feed_rev: null },
  //   milling: {
  //     roughing: { cutting_speed: null, feed_rev: null },
  //     finishing: { cutting_speed: null, feed_rev: null },
  //   },
  //   turning: {
  //     roughing: { cutting_speed: null, feed_rev: null, depth: null },
  //     finishing: { cutting_speed: null, feed_rev: null, depth: null },
  //     facing: { cutting_speed: null, feed_rev: null, depth: null },
  //   },
  // },
};

const initialState = {
  materials: [],
  activeMaterial: initialMaterial,
  materialDialog: {
    isOpen: false,
    mode: null, //  null | 'add' | 'edit'
    activeTab: "general",
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const fetchAllMaterials = createAsyncThunk(
  "materials/getMaterials",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/materials/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createMaterial = createAsyncThunk(
  "materials/createMaterial",
  async (material, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/materials/create`, material);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateMaterial = createAsyncThunk(
  "materials/updateMaterial",
  async (material, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/materials/${material.material_id}`,
        material
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteMaterial = createAsyncThunk(
  "materials/deleteMaterial",
  async (material, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/materials/${material.material_id}`);
      return material.material_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    /* --- Material ------------------------------------------- */
    setActiveMaterial: (state, action) => {
      state.activeMaterial = state.materials.find(
        (material) => material.material_id === action.payload.material_id
      );
    },
    resetActiveMaterial: (state) => {
      state.activeMaterial = initialMaterial;
    },
    /* --- Material dialog -------------------------------------*/
    startAddingMaterial: (state) => {
      state.materialDialog.mode = "add";
      state.materialDialog.activeTab = "general";
      state.materialDialog.isOpen = true;
    },
    startEditingMaterial: (state) => {
      state.materialDialog.mode = "edit";
      state.materialDialog.activeTab = "general";
      state.materialDialog.isOpen = true;
    },
    closeMaterialDialog: (state) => {
      state.materialDialog.isOpen = false;
      state.materialDialog.activeTab = "general";
      state.materialDialog.mode = null;
      state.activeMaterial = initialMaterial;
    },
    setMaterialActiveTab: (state, action) => {
      state.materialDialog.activeTab = action.payload;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingMaterial: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- READ ------------------------------------------- */
      .addCase(fetchAllMaterials.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchAllMaterials.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.materials = action.payload;
      })
      .addCase(fetchAllMaterials.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createMaterial.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createMaterial.fulfilled, (state, action) => {
        state.activeMaterial = action.payload;
        state.materials.push(action.payload);
        state.uploading.status = "succeeded";
        state.materialDialog.mode = "edit";
      })
      .addCase(createMaterial.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updateMaterial.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updateMaterial.fulfilled, (state, action) => {
        state.activeMaterial = action.payload;
        const material_index = state.materials.findIndex(
          (material) => material.material_id === action.payload.material_id
        );
        state.materials[material_index] = action.payload;
        state.uploading.status = "succeeded";
      })
      .addCase(updateMaterial.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deleteMaterial.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deleteMaterial.fulfilled, (state, action) => {
        const material_index = state.materials.findIndex(
          (material) => material.material_id === action.payload
        );
        state.materials.splice(material_index, 1);
        state.activeMaterial = initialMaterial;
        state.deleting.status = "succeeded";
      })
      .addCase(deleteMaterial.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Materials ------------------------------------------------------------------- */
export const getActiveMaterial = (state) => state.materials.activeMaterial;
export const selectAllMaterials = (state) => state.materials.materials;

/* --- Material Dialog ------------------------------------------------------------- */
export const getMaterialDialogState = (state) => state.materials.materialDialog;

/* --- Material Functions ---------------------------------------------------------- */
export const getMaterialsDownloadState = (state) => state.materials.downloading;
export const getMaterialUploadState = (state) => state.materials.uploading;
export const getMaterialDeleteState = (state) => state.materials.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActiveMaterial,
  resetActiveMaterial,
  startAddingMaterial,
  startEditingMaterial,
  closeMaterialDialog,
  setMaterialActiveTab,
  startDeletingMaterial,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = materialsSlice.actions;

export default materialsSlice.reducer;
