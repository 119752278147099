import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { default as Auth } from "./components/auth/Authenticated";
import { default as Pub } from "./components/auth/PublicRoute";
import PageLogin from "./pages/login/PageLogin";
import PageMaterials from "./pages/materials/PageMaterials";
import PagePart from "./pages/parts/PagePart";
import PageParts from "./pages/parts/PageParts";
import PageRegister from "./pages/register/PageRegister";
import PageSettings from "./pages/settings/PageSettings";
import PageUsers from "./pages/users/PageUsers";
import SideNav from "./components/bars/SideNav";
import Navbar from "./components/bars/Navbar";
import Page404 from "./pages/static/Page404";
import CircularProgressBasic from "./components/progress/CircularProgressBasic";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <Suspense fallback={<CircularProgressBasic />}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <main className="content">
              <Routes>
                <Route element={<SideNav />}>
                  <Route element={<Navbar />}>
                    <Route
                      path="/"
                      exact
                      element={
                        <Auth>
                          <Navigate to="/materials" replace />
                        </Auth>
                      }
                    />
                    <Route
                      path="/materials"
                      exact
                      element={
                        <Auth>
                          <PageMaterials />
                        </Auth>
                      }
                    />
                    <Route
                      path="/parts"
                      exact
                      element={
                        <Auth>
                          <PageParts />
                        </Auth>
                      }
                    />
                    <Route
                      path="/parts/:id"
                      exact
                      element={
                        <Auth>
                          <PagePart />
                        </Auth>
                      }
                    />
                    <Route
                      path="/settings"
                      exact
                      element={
                        <Auth>
                          <PageSettings />
                        </Auth>
                      }
                    />
                    <Route
                      path="/users"
                      exact
                      element={
                        <Auth>
                          <PageUsers />
                        </Auth>
                      }
                    />
                  </Route>
                </Route>
                {/*<Route path="/" exact element={<Homepage />} />*/}
                <Route
                  path="/login"
                  exact
                  element={
                    <Pub>
                      <PageLogin />
                    </Pub>
                  }
                />
                <Route
                  path="/register"
                  exact
                  element={
                    <Pub>
                      <PageRegister />
                    </Pub>
                  }
                />
                <Route path="/404" exact element={<Page404 />} />
                <Route
                  path="*"
                  exact
                  element={<Navigate to="/404" replace />}
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Suspense>
  );
}

export default App;
