import { useSelector, useDispatch } from 'react-redux';
import {
    updateMaterial,
    closeLoadingErrorDialog,
    getActiveMaterial,
    getMaterialDialogState,
    getMaterialUploadState,
} from '../../store/slices/materialsSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Box, Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import DividerLeftLabel from '../../components/dialogs/DividerLeftLabel'


const machiningParametersSchema = yup.object().shape({
    turn_rough_Vc: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    turn_rough_Frev: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    turn_rough_Ap: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),

    turn_finish_Vc: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    turn_finish_Frev: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    turn_finish_Ap: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),

    turn_face_Vc: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    turn_face_Frev: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    turn_face_Ap: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),

    mill_rough_Vc: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    mill_rough_Frev: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),

    mill_finish_Vc: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    mill_finish_Frev: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),

    drill_Vc: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive'),
    drill_Frev: yup.number().nullable(true).typeError('form.error.number').positive('form.error.positive')
});


function MachiningParametersTab() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeMaterial = useSelector(getActiveMaterial);
    const materialDialogState = useSelector(getMaterialDialogState);
    const uploadState = useSelector(getMaterialUploadState);

    const initialParameters = {
        turn_rough_Vc: activeMaterial.machining_parameters?.turning.roughing.cutting_speed,
        turn_rough_Frev: activeMaterial.machining_parameters?.turning.roughing.feed_rev,
        turn_rough_Ap: activeMaterial.machining_parameters?.turning.roughing.depth,

        turn_finish_Vc: activeMaterial.machining_parameters?.turning.finishing.cutting_speed,
        turn_finish_Frev: activeMaterial.machining_parameters?.turning.finishing.feed_rev,
        turn_finish_Ap: activeMaterial.machining_parameters?.turning.finishing.depth,

        turn_face_Vc: activeMaterial.machining_parameters?.turning.facing.cutting_speed,
        turn_face_Frev: activeMaterial.machining_parameters?.turning.facing.feed_rev,
        turn_face_Ap: activeMaterial.machining_parameters?.turning.facing.depth,

        mill_rough_Vc: activeMaterial.machining_parameters?.milling.roughing.cutting_speed,
        mill_rough_Frev: activeMaterial.machining_parameters?.milling.roughing.feed_rev,

        mill_finish_Vc: activeMaterial.machining_parameters?.milling.finishing.cutting_speed,
        mill_finish_Frev: activeMaterial.machining_parameters?.milling.finishing.feed_rev,

        drill_Vc: activeMaterial.machining_parameters?.drilling.cutting_speed,
        drill_Frev: activeMaterial.machining_parameters?.drilling.feed_rev,
    }

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridContainerStyle = {
        p: isAboveMobileSize ? '16px 0 0 0' : '16px 0 0 0',
        overflow: 'auto'
    };

    /* ----- UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const material = {
            material_id: activeMaterial.material_id,
            machining_parameters: {
                drilling: {
                    cutting_speed: (values.drill_Vc ? values.drill_Vc : null),
                    feed_rev: (values.drill_Frev ? values.drill_Frev : null),
                },
                milling: {
                    roughing: {
                        cutting_speed: (values.mill_rough_Vc ? values.mill_rough_Vc : null),
                        feed_rev: (values.mill_rough_Frev ? values.mill_rough_Frev : null)
                    },
                    finishing: {
                        cutting_speed: (values.mill_finish_Vc ? values.mill_finish_Vc : null),
                        feed_rev: (values.mill_finish_Frev ? values.mill_finish_Frev : null)
                    },
                },
                turning: {
                    roughing: {
                        cutting_speed: (values.turn_rough_Vc ? values.turn_rough_Vc : null),
                        feed_rev: (values.turn_rough_Frev ? values.turn_rough_Frev : null),
                        depth: (values.turn_rough_Ap ? values.turn_rough_Ap : null)
                    },
                    finishing: {
                        cutting_speed: (values.turn_finish_Vc ? values.turn_finish_Vc : null),
                        feed_rev: (values.turn_finish_Frev ? values.turn_finish_Frev : null),
                        depth: (values.turn_finish_Ap ? values.turn_finish_Ap : null)
                    },
                    facing: {
                        cutting_speed: (values.turn_face_Vc ? values.turn_face_Vc : null),
                        feed_rev: (values.turn_face_Frev ? values.turn_face_Frev : null),
                        depth: (values.turn_face_Ap ? values.turn_face_Ap : null)
                    },
                },
            },
        };

        dispatch(updateMaterial(material));

    };

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialParameters}
                validationSchema={machiningParametersSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Box
                            width='100%'
                            pr="10px"
                            sx={{
                                maxHeight: isAboveMobileSize ? '354px' : '354px', // set height order: --- 3/3 ---
                                overflow: 'auto'
                            }}
                        >
                            <DividerLeftLabel label={t('label.process.turning')} />
                            <Grid
                                container
                                sx={gridContainerStyle}
                            >
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Roughing - Vc[m/s]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_rough_Vc || ''}
                                        name='turn_rough_Vc'
                                        error={!!touched.turn_rough_Vc && !!errors.turn_rough_Vc}
                                        helperText={touched.turn_rough_Vc && <>{t(errors.turn_rough_Vc)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Roughing - F[mm/rev]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_rough_Frev || ''}
                                        name='turn_rough_Frev'
                                        error={!!touched.turn_rough_Frev && !!errors.turn_rough_Frev}
                                        helperText={touched.turn_rough_Frev && <>{t(errors.turn_rough_Frev)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Roughing - Ap[mm]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_rough_Ap || ''}
                                        name='turn_rough_Ap'
                                        error={!!touched.turn_rough_Ap && !!errors.turn_rough_Ap}
                                        helperText={touched.turn_rough_Ap && <>{t(errors.turn_rough_Ap)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Finishing - Vc[m/s]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_finish_Vc || ''}
                                        name='turn_finish_Vc'
                                        error={!!touched.turn_finish_Vc && !!errors.turn_finish_Vc}
                                        helperText={touched.turn_finish_Vc && <>{t(errors.turn_finish_Vc)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Finishing - F[mm/rev]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_finish_Frev || ''}
                                        name='turn_finish_Frev'
                                        error={!!touched.turn_finish_Frev && !!errors.turn_finish_Frev}
                                        helperText={touched.turn_finish_Frev && <>{t(errors.turn_finish_Frev)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Finishing - Ap[mm]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_finish_Ap || ''}
                                        name='turn_finish_Ap'
                                        error={!!touched.turn_finish_Ap && !!errors.turn_finish_Ap}
                                        helperText={touched.turn_finish_Ap && <>{t(errors.turn_finish_Ap)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Facing - Vc[m/s]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_face_Vc || ''}
                                        name='turn_face_Vc'
                                        error={!!touched.turn_face_Vc && !!errors.turn_face_Vc}
                                        helperText={touched.turn_face_Vc && <>{t(errors.turn_face_Vc)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Facing - F[mm/rev]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_face_Frev || ''}
                                        name='turn_face_Frev'
                                        error={!!touched.turn_face_Frev && !!errors.turn_face_Frev}
                                        helperText={touched.turn_face_Frev && <>{t(errors.turn_face_Frev)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 4 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Facing - Ap[mm]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.turn_face_Ap || ''}
                                        name='turn_face_Ap'
                                        error={!!touched.turn_face_Ap && !!errors.turn_face_Ap}
                                        helperText={touched.turn_face_Ap && <>{t(errors.turn_face_Ap)}</>}
                                    />
                                </Grid>
                            </Grid>
                            <DividerLeftLabel label={t('label.process.milling')} />
                            <Grid
                                container
                                sx={gridContainerStyle}
                            >
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 6 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Roughing - Vc[m/s]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mill_rough_Vc || ''}
                                        name='mill_rough_Vc'
                                        error={!!touched.mill_rough_Vc && !!errors.mill_rough_Vc}
                                        helperText={touched.mill_rough_Vc && <>{t(errors.mill_rough_Vc)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 6 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Roughing - F[mm/rev]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mill_rough_Frev || ''}
                                        name='mill_rough_Frev'
                                        error={!!touched.mill_rough_Frev && !!errors.mill_rough_Frev}
                                        helperText={touched.mill_rough_Frev && <>{t(errors.mill_rough_Frev)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 6 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Finishing - Vc[m/s]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mill_finish_Vc || ''}
                                        name='mill_finish_Vc'
                                        error={!!touched.mill_finish_Vc && !!errors.mill_finish_Vc}
                                        helperText={touched.mill_finish_Vc && <>{t(errors.mill_finish_Vc)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 6 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Finishing - F[mm/rev]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.mill_finish_Frev || ''}
                                        name='mill_finish_Frev'
                                        error={!!touched.mill_finish_Frev && !!errors.mill_finish_Frev}
                                        helperText={touched.mill_finish_Frev && <>{t(errors.mill_finish_Frev)}</>}
                                    />
                                </Grid>
                            </Grid>
                            <DividerLeftLabel label={t('label.process.drilling')} />
                            <Grid
                                container
                                sx={gridContainerStyle}
                            >
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 6 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='Vc[m/s]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drill_Vc || ''}
                                        name='drill_Vc'
                                        error={!!touched.drill_Vc && !!errors.drill_Vc}
                                        helperText={touched.drill_Vc && <>{t(errors.drill_Vc)}</>}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={isAboveMobileSize ? 6 : 12}
                                    sx={gridTextFieldStyle}
                                >
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        label='F[mm/rev]'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drill_Frev || ''}
                                        name='drill_Frev'
                                        error={!!touched.drill_Frev && !!errors.drill_Frev}
                                        helperText={touched.drill_Frev && <>{t(errors.drill_Frev)}</>}
                                    />
                                </Grid>
                            </Grid>

                            <Box
                                position='absolute'
                                bottom='0'
                                left='0'
                                right='0'
                            >
                                <SubmitResetButtonGroup
                                    onSubmit={handleSubmit}
                                    labelSubmit={materialDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                                    labelReset={materialDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                                    loading={uploadState.status === 'loading'}
                                />
                            </Box>
                        </Box>

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default MachiningParametersTab