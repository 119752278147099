import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { Sidebar, Menu, MenuItem, /*SubMenu*/ } from 'react-pro-sidebar';
import { Box, Divider, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { tokens } from '../../theme';
import useMediaQuery from '../../hooks/useMediaQuery';
// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
// import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
// import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
// import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
// import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import TableViewOutlinedIcon from '@mui/icons-material/TableViewOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
// import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import background from '../../assets/background/main_background_dark.jpg';


function SideNav() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(true);
    const [selected, setSelected] = useState(JSON.parse(window.localStorage.getItem('SIDEBAR_SELECTED_OPTION')));
    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);


    useEffect(() => {
        selected ? (
            window.localStorage.setItem('SIDEBAR_SELECTED_OPTION', JSON.stringify(selected))
        ) : (
            window.localStorage.setItem('SIDEBAR_SELECTED_OPTION', JSON.stringify('orders'))    // first app open
        )
    }, [selected]);


    return (
        <Box
            display='flex'
            width='100%'
            height='100%'
            sx={{
                backgroundImage: theme.palette.mode === 'dark' ? `url(${background})` : null,
                backgroundSize: 'cover',
                backgroundPositionX: '0%'
            }}
        >
            <Sidebar
                onMouseEnter={() => setCollapsed(false)}
                onMouseLeave={() => setCollapsed(true)}
                position='fixed' left={0} top={0} bottom={0}
                collapsed={collapsed}
                width='250px'
                collapsedWidth={isAboveMobileSize ? settings.SIDEBAR_WIDTH : settings.SIDEBAR_WIDTH_MOBILE}
                backgroundColor={colors.primary[400]}
                rootStyles={{ border: 'none', justifyContent: 'center' }}
            >
                <Menu
                    menuItemStyles={{
                        root: {
                            fontSize: '15px',
                            fontWeight: 400,
                        },
                        SubMenuExpandIcon: () => {
                            if (collapsed)
                                return {
                                    color: 'transparent'
                                }
                        },
                        subMenuContent: {
                            backgroundColor: 'transparent',
                        },
                        button: ({ level, active, disabled }) => {
                            // apply styles on first level elements of the tree
                            if (level === 0)
                                return {
                                    color: disabled ? undefined : (active ? colors.assigned.sidebarActive1 : colors.grey[100]),
                                    backgroundColor: colors.primary[400],
                                    margin: '5px 8px 5px 8px',
                                    paddingLeft: '0.7vw',
                                    borderRadius: '3px',
                                    ":hover": {
                                        backgroundColor: colors.primary[500],
                                    }
                                };
                            // apply styles on second level elements of the tree
                            if (level === 1)
                                return {
                                    height: '36px',
                                    color: disabled ? undefined : colors.grey[100],
                                    backgroundColor: active ? colors.primary[500] : undefined,
                                    margin: '2px 8px 2px 8px',
                                    paddingLeft: '60px',
                                    paddingTop: '0px',
                                    borderRadius: '3px',
                                    ":hover": {
                                        backgroundColor: colors.primary[500],
                                    }
                                };
                        },

                    }}
                    rootStyles={{
                        paddingTop: '150px',
                    }}
                >
                    {/* <MenuItem
                        icon={<DashboardOutlinedIcon />}
                        active={selected === 'dashboard'}
                        onClick={() => setSelected('dashboard')}
                        component={<Link to="/dashboard" />}
                    >
                        {t('page.dashboards')}
                    </MenuItem> */}
                    {/* <Divider variant="middle" /> */}
                    <MenuItem
                        icon={<TableViewOutlinedIcon />}
                        active={selected === 'parts'}
                        onClick={() => setSelected('parts')}
                        component={<Link to="/parts" />}
                    >
                        {t('page.parts')}
                    </MenuItem>
                    <Divider variant="middle" />
                    {/* <SubMenu
                        disabled={false}
                        label={t('page.factory')}
                        icon={<PrecisionManufacturingOutlinedIcon />}
                        active={selected === 'places' || selected === 'tools'}
                    >
                        <MenuItem
                            active={selected === 'places'}
                            onClick={() => setSelected('places')}
                            component={<Link to="/places" />}
                        >
                            {t('page.places')}
                        </MenuItem>
                        <MenuItem
                            disabled
                            active={selected === 'tools'}
                            onClick={() => setSelected('tools')}
                            component={<Link to="/" />}
                        >
                            {t('page.tools')}
                        </MenuItem>
                    </SubMenu> */}
                    {/* <SubMenu
                        label={t('page.technology')}
                        icon={<SummarizeOutlinedIcon />}
                        active={selected === 'products' || selected === 'technology' || selected === 'materials'}
                    >
                        <MenuItem
                            active={selected === 'products'}
                            onClick={() => setSelected('products')}
                            component={<Link to="/products" />}
                        >
                            {t('page.products')}
                        </MenuItem>
                        <MenuItem
                            active={selected === 'materials'}
                            onClick={() => setSelected('materials')}
                            component={<Link to="/materials" />}
                        >
                            {t('page.materials')}
                        </MenuItem>
                        <MenuItem
                            disabled={false}
                            active={selected === 'technology'}
                            onClick={() => setSelected('technology')}
                            component={<Link to="/technology" />}
                        >
                            {t('page.technology')}
                        </MenuItem>
                    </SubMenu> */}
                    {/* <MenuItem
                        disabled={false}
                        icon={<MobileScreenShareIcon />}
                        active={selected === 'impersonate'}
                        onClick={() => setSelected('impersonate')}
                        component={<Link to="/impersonate" />}
                    >
                        {t('page.impersonate')}
                    </MenuItem> */}
                    <MenuItem
                        icon={<ViewInArOutlinedIcon />}
                        active={selected === 'materials'}
                        disabled={false}
                        onClick={() => setSelected('materials')}
                        component={<Link to="/materials" />}
                    >
                        {t('page.materials')}
                    </MenuItem>
                    <Divider variant="middle" />
                    <MenuItem
                        icon={<PeopleOutlinedIcon />}
                        active={selected === 'users'}
                        disabled={false}
                        onClick={() => setSelected('users')}
                        component={<Link to="/users" />}
                    >
                        {t('page.team')}
                    </MenuItem>
                    {/* <Divider variant="middle" /> */}
                    {/* <MenuItem
                        icon={<Inventory2OutlinedIcon />}
                        active={selected === 'archive'}
                        disabled={false}
                        onClick={() => setSelected('archive')} 
                        component={<Link to="/archive" />}
                    >
                        {t('page.archive')}
                    </MenuItem> */}
                    <Divider variant="middle" />
                    <MenuItem
                        icon={<TuneOutlinedIcon />}
                        active={selected === 'settings'}
                        disabled={false}
                        onClick={() => setSelected('settings')}
                        component={<Link to="/settings" />}
                    >
                        {t('page.settings')}
                    </MenuItem>
                </Menu>
            </Sidebar>
            <Outlet />
        </Box>
    );
};

export default SideNav;