import { useSelector, useDispatch } from 'react-redux';
import {
    createMaterial,
    updateMaterial,
    resetActiveMaterial,
    closeLoadingErrorDialog,
    getActiveMaterial,
    getMaterialDialogState,
    getMaterialUploadState,
} from '../../store/slices/materialsSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import { getMaterialGroupName, materialGroups } from '../../static/materialGroups';


let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const materialSchema = yup.object().shape({
    material_group: yup
        .string()
        .required('form.error.field_required'),
    material_name: yup
        .string()
        .required('form.error.field_required'),
    density: yup
        .number()
        .typeError('form.error.number')
        .positive('form.error.positive')
        .required('form.error.field_required')
        .test(
            "title: max two decimals",
            "form.error.max_two_decimals",
            (val) => {
                // eslint-disable-next-line
                if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                }
                return true;
            }),
    description: yup
        .string()
});


function FormMaterial() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeMaterial = useSelector(getActiveMaterial);
    const materialDialogState = useSelector(getMaterialDialogState);
    const uploadState = useSelector(getMaterialUploadState);

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridMultilineFieldStyle = { height: '95px', px: '15px' };
    const gridCreatedUpdatedStyle = { px: '15px' };

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const material = {
            material_id: activeMaterial.material_id,
            material_name: values.material_name,
            material_group: values.material_group,
            density: values.density,
            description: values.description
        };

        if (materialDialogState.mode === 'add') {
            dispatch(createMaterial(material));
        };
        if (materialDialogState.mode === 'edit') {
            dispatch(updateMaterial(material));
        };
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (materialDialogState.mode === 'add') {
            dispatch(resetActiveMaterial());
        };
    };

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeMaterial}
                validationSchema={materialSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Grid
                            container
                            height={isAboveMobileSize ? 'auto' : '360px'} // set height order: --- 3/3 ---
                            p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 12px 0'}
                            overflow='auto'
                        >
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.material_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.material_name || ''}
                                    name='material_name'
                                    error={!!touched.material_name && !!errors.material_name}
                                    helperText={touched.material_name && <>{t(errors.material_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <Autocomplete
                                    disableClearable
                                    options={materialGroups.map(x => t(x.group_name))}
                                    onChange={(e, value) => {
                                        handleChange({
                                            ...e, target: {
                                                ...e.target,
                                                name: "material_group",
                                                value: materialGroups.find(mat => t(mat.group_name) === value)?.material_group,
                                            },
                                        });
                                    }}
                                    value={t(getMaterialGroupName(values.material_group)) || null}
                                    isOptionEqualToValue={(option, value) => option === value}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            size='small'
                                            label={t('label.material_group')}
                                            onBlur={handleBlur}
                                            error={!!touched.material_group && !!errors.material_group}
                                            helperText={touched.material_group && <>{t(errors.material_group)}</>}
                                        />}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.density')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.density || ''}
                                    name='density'
                                    error={!!touched.density && !!errors.density}
                                    helperText={touched.density && <>{t(errors.density)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridMultilineFieldStyle}
                            >
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.description')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description || ''}
                                    name='description'
                                    error={!!touched.description && !!errors.description}
                                    helperText={touched.description && <>{t(errors.description)}</>}
                                />
                            </Grid>
                            {materialDialogState.mode === 'edit' ?
                                <Grid
                                    item
                                    xs={12}
                                //mt='-4px'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeMaterial.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeMaterial.updated}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>

                        <Box
                            position='absolute'
                            bottom='0'
                            left='0'
                            right='0'
                        >
                            <SubmitResetButtonGroup
                                onSubmit={handleSubmit}
                                onReset={handleResetClick}
                                labelSubmit={materialDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                                labelReset={materialDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                                loading={uploadState.status === 'loading'}
                            />
                        </Box>

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default FormMaterial;