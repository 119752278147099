import { Button } from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';


function TableActionDetails(props) {

    const { onClick } = props;


    return (
        <Button
            variant='outlined'
            color='warning'
            onClick={onClick}
        >
            <OpenInNewOutlinedIcon />
        </Button>
    );
};

export default TableActionDetails;