import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import settings from '../../config.json';
import { Box, IconButton, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import useMediaQuery from '../../hooks/useMediaQuery';
import MobileMenuDrawer from '../drawers/MobileMenuDrawer';
import LoggedUser from './LoggedUser';
import LoggedAccount from './LoggedAccount';
import LanguageSwitch from '../switches/LanguageSwitch';
import ThemeToggler from '../switches/ThemeToggler';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
//import ImesLogo from '../../assets/logos/ImesLogo';



function Navbar() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isMenuOppened, setIsMenuOppened] = useState(false);
    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY);
    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);


    return (
        <Box width='100%' height='100%'>    {/* all area right to sidebar */}

            {/* NAVBAR */}
            <Box
                position='fixed' top={0} left={isAboveMobileSize ? settings.SIDEBAR_WIDTH : settings.SIDEBAR_WIDTH_MOBILE} right={0} zIndex='0'
                display='flex' alignItems='center'
                height={isAboveMediumScreens ? settings.NAVBAR_HEIGHT : '50px'}
                backgroundColor={isAboveMediumScreens ? colors.primary[400] : null}
            >
                {isAboveMediumScreens ? (
                    <Box
                        display='flex' alignItems='center' justifyContent='space-between'
                        width='80%'
                        mx='auto'
                    >
                        {/* LEFT SIDE */}
                        <Box
                            display='flex'
                            alignItems='center'
                        >
                            {/* <ImesLogo scale={0.5} color={colors.grey[100]} /> */}
                            <LoggedAccount />
                        </Box>

                        {/* RIGHT SIDE */}
                        <Box display='flex' alignItems='center' gap='80px' >
                            <Box display='flex' alignItems='center' gap='10px'>
                                <ThemeToggler />
                                <LanguageSwitch />
                            </Box>
                            <LoggedUser />
                        </Box>
                    </Box>
                ) : (
                    <Box
                        display='flex' justifyContent='flex-end'
                        width='100%' px='12px'
                    >
                        <IconButton
                            color="primary"
                            onClick={() => setIsMenuOppened(true)}
                        >
                            <MenuRoundedIcon sx={{ fontSize: '40px', color: colors.grey[100] }} />
                        </IconButton>
                    </Box>
                )}
            </Box>

            {/* MENU */}
            <MobileMenuDrawer
                open={isMenuOppened}
                onClose={() => setIsMenuOppened(false)}
            />

            {/* PAGES */}
            <Box
                position='fixed'
                top={isAboveMediumScreens ? settings.NAVBAR_HEIGHT : '50px'} bottom={0}
                left={isAboveMobileSize ? settings.SIDEBAR_WIDTH : settings.SIDEBAR_WIDTH_MOBILE} right={0}
                pt={{ xs: '0', md: '30px' }}
                pb={{ xs: '10px', md: '50px' }}
                px={{ xs: '10px', md: '50px' }}
                sx={{
                    overflow: 'auto',
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default Navbar;