import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    createUser,
    updateUser,
    resetActiveUser,
    closeLoadingErrorDialog,
    getActiveUser,
    getUserDialogState,
    getUserUploadState,
} from '../../store/slices/usersSlice';
import { getTimezone } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import moment from 'moment-timezone';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import PasswordVisibility from '../../components/switches/PasswordVisibility';


/* eslint-disable */
const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const phoneRegExp = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/)

const userShape = {
    email: yup
        .string()
        .email('form.error.email_invalid_format')
        .matches(emailRegExp, 'form.error.email_invalid_format')
        .required('form.error.email_required'),
    username: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
        .required('form.error.field_required'),
    first_name: yup
        .string()
        .required('form.error.field_required'),
    last_name: yup
        .string()
        .required('form.error.field_required'),
    mobile: yup
        .string()
        .matches(phoneRegExp, 'form.error.phone_invalid_format'),
    position: yup
        .string(),
    hire_date: yup
        .date(),
    leave_date: yup
        .date()
};

const addSchema = yup.object().shape({
    ...userShape, password: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
        .required('form.error.password_required')
});

const editSchema = yup.object().shape({
    ...userShape, password: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
});


function FormUser() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeUser = useSelector(getActiveUser);
    const userDialogState = useSelector(getUserDialogState);
    const uploadState = useSelector(getUserUploadState);
    const timezone = useSelector(getTimezone)

    const [showPassword, setShowPassword] = useState({ password: false, cpassword: false });

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridCreatedUpdatedStyle = { px: '15px' };

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {
        const formated_1 = formatDates(values);

        if (userDialogState.mode === 'add') {
            dispatch(createUser(formated_1));
        };
        if (userDialogState.mode === 'edit') {
            const formated_2 = removeEmptyPassword(formated_1);
            dispatch(updateUser(formated_2));
        };
    };

    const removeEmptyPassword = (values) => {
        if (values.password === '') {
            delete values.password;
            return values;
        } else {
            return values;
        };
    };

    const formatDates = (values) => {
        return {
            ...values,
            hire_date:
                (values.hire_date === ""
                    ? "1970-01-01T00:00:00"
                    : moment(values.hire_date).format('YYYY-MM-DD') + 'T00:00:00'),
            leave_date:
                (values.leave_date === ""
                    ? "1970-01-01T00:00:00"
                    : moment(values.leave_date).format('YYYY-MM-DD') + 'T00:00:00')
        };
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (userDialogState.mode === 'add') {
            dispatch(resetActiveUser());
        };
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={userDialogState.mode === 'add' ? { ...activeUser, password: "" } : activeUser}
                validationSchema={userDialogState.mode === 'add' ? addSchema : editSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Grid
                            container
                            height={isAboveMobileSize ? 'auto' : '435px'} // set height order: --- 3/3 ---
                            p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 12px 0'}
                            overflow='auto'
                        >
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.first_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.first_name || ''}
                                    name='first_name'
                                    error={!!touched.first_name && !!errors.first_name}
                                    helperText={touched.first_name && <>{t(errors.first_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.last_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.last_name || ''}
                                    name='last_name'
                                    error={!!touched.last_name && !!errors.last_name}
                                    helperText={touched.last_name && <>{t(errors.last_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.email')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email || ''}
                                    name='email'
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && <>{t(errors.email)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type={showPassword.password ? 'text' : 'password'}
                                    size="small"
                                    label={t('label.password')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password || ''}
                                    name='password'
                                    error={!!touched.password && !!errors.password}
                                    helperText={touched.password && <>{t(errors.password)}</>}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <PasswordVisibility
                                                    showPassword={showPassword.password}
                                                    onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                                                />
                                            </InputAdornment>
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.username')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.username || ''}
                                    name='username'
                                    error={!!touched.username && !!errors.username}
                                    helperText={touched.username && <>{t(errors.username)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.position')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.position || ''}
                                    name='position'
                                    error={!!touched.position && !!errors.position}
                                    helperText={touched.position && <>{t(errors.position)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='date'
                                    size="small"
                                    label={t('label.hire_date')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                        values.hire_date !== "1970-01-01T00:00:00"
                                            ? moment.utc(values.hire_date).tz(timezone).format('YYYY-MM-DD')
                                            : '' || ''
                                    }
                                    name='hire_date'
                                    error={!!touched.hire_date && !!errors.hire_date}
                                    helperText={touched.hire_date && <>{t(errors.hire_date)}</>}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='date'
                                    size="small"
                                    label={t('label.leave_date')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                        values.leave_date !== "1970-01-01T00:00:00"
                                            ? moment.utc(values.leave_date).tz(timezone).format('YYYY-MM-DD')
                                            : '' || ''
                                    }
                                    name='leave_date'
                                    error={!!touched.leave_date && !!errors.leave_date}
                                    helperText={touched.leave_date && <>{t(errors.leave_date)}</>}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12} sm={6}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.mobile')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.mobile || ''}
                                    name='mobile'
                                    error={!!touched.mobile && !!errors.mobile}
                                    helperText={touched.mobile && <>{t(errors.mobile)}</>}
                                />
                            </Grid>
                            {userDialogState.mode === 'edit' ?
                                <Grid
                                    item
                                    xs={12} sm={6}
                                    mt='-4px'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeUser.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeUser.updated}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>

                        <Box
                            position='absolute'
                            bottom='0'
                            left='0'
                            right='0'
                        >
                            <SubmitResetButtonGroup
                                onSubmit={handleSubmit}
                                onReset={handleResetClick}
                                labelSubmit={userDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                                labelReset={userDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                                loading={uploadState.status === 'loading'}
                            />
                        </Box>

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default FormUser;