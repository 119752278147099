import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

function SaveButton(props) {

    const { onClick, loading, type } = props

    return (
        <LoadingButton
            variant="contained"
            color="info"
            onClick={onClick}
            loading={loading}
            type={type}
        //sx={{ paddingX: '50px' }}
        >
            <SaveIcon />
        </LoadingButton>
    )
}

export default SaveButton;