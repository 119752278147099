import { useSelector, useDispatch } from 'react-redux';
import {
    updatePart,
    closeLoadingErrorDialog,
    getActivePart,
    getPartInfoState,
    getPartUploadState,
    startEditingPartInfo,
    finishEditingPartInfo
} from '../../store/slices/partsSlice';
import { getTimezone } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../theme';
import { Box, Grid, TextField, Typography, useTheme } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import EditSubmitButtonGroup from '../../components/buttons/EditSubmitButtonGroup';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import moment from 'moment-timezone';


const partSchema = yup.object().shape({
    part_name: yup
        .string(),
    part_number: yup
        .string(),
    drawing_number: yup
        .string(),
    customer: yup
        .string(),
    description: yup
        .string()
});

function TilePartInfo() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activePart = useSelector(getActivePart);
    const partInfoState = useSelector(getPartInfoState);
    const uploadState = useSelector(getPartUploadState);
    const timezone = useSelector(getTimezone)

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const part = {
            part_id: activePart.part_id,
            part_name: values.part_name,
            part_number: values.part_number,
            drawing_number: values.drawing_number,
            customer: values.customer,
            description: values.description
        };

        dispatch(updatePart(part));
    };

    const handleEditClick = () => {
        dispatch(startEditingPartInfo());
    };

    const handleEditOffClick = () => {
        dispatch(finishEditingPartInfo());
    };

    /* -----------------------------------------------------------------------------------*/

    const rowStyle = {
        minHeight: "52px",
    };

    const leftColumnStyle = {
        width: '140px'
    };

    const labelStyle = {
        color: colors.grey[200],
        fontSize: '12px',
        textAlign: 'right',
        alignItems: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        pt: "4px",
    };

    const textFieldStyle = {
        '& fieldset': {
            border: partInfoState.isEdited ? 'auto' : 'none',
        },
    };;

    const textFieldInputProps = {
        readOnly: partInfoState.isEdited ? false : true,
        style: {
            padding: "4px 8px"
        },
    };


    return (
        <>
            <Box
                mx='10px'
            >
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={activePart}
                    validationSchema={partSchema}
                    enableReinitialize
                >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                        <Form>

                            {/* ------- HEADER ----------------------------------------------------- */}
                            <Box
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                                ml='20px'
                                my='10px'
                            >
                                <Typography
                                    variant="h6"
                                    color={colors.assigned.tableHeader}
                                    fontWeight='bold'
                                >
                                    {t('label.part_information')}
                                </Typography>
                                <EditSubmitButtonGroup
                                    editMode={partInfoState.isEdited}
                                    onSubmit={handleSubmit}
                                    onEdit={handleEditClick}
                                    onEditOff={handleEditOffClick}
                                    loading={uploadState.status === 'loading'}
                                />
                            </Box>

                            {/* ------- FORM ------------------------------------------------------- */}
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.part_name')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        //label={t('label.part_name')}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.part_name || ''}
                                        name='part_name'
                                        error={!!touched.part_name && !!errors.part_name}
                                        helperText={touched.part_name && <>{t(errors.part_name)}</>}
                                        sx={textFieldStyle}
                                        inputProps={textFieldInputProps}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.part_number')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        //label={t('label.part_number')}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.part_number || ''}
                                        name='part_number'
                                        error={!!touched.part_number && !!errors.part_number}
                                        helperText={touched.part_number && <>{t(errors.part_number)}</>}
                                        sx={textFieldStyle}
                                        inputProps={textFieldInputProps}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.drawing_number')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        //label={t('label.drawing_number')}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.drawing_number || ''}
                                        name='drawing_number'
                                        error={!!touched.drawing_number && !!errors.drawing_number}
                                        helperText={touched.drawing_number && <>{t(errors.drawing_number)}</>}
                                        sx={textFieldStyle}
                                        inputProps={textFieldInputProps}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.customer')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        //label={t('label.customer')}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.customer || ''}
                                        name='customer'
                                        error={!!touched.customer && !!errors.customer}
                                        helperText={touched.customer && <>{t(errors.customer)}</>}
                                        sx={textFieldStyle}
                                        inputProps={textFieldInputProps}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.description')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        multiline
                                        rows={3}
                                        fullWidth
                                        variant='outlined'
                                        type='text'
                                        size="small"
                                        //label={t('label.description')}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.description || ''}
                                        name='description'
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && <>{t(errors.description)}</>}
                                        sx={[textFieldStyle, {
                                            '& .css-1sns44c-MuiInputBase-root-MuiOutlinedInput-root': {
                                                padding: 0, //  to remove additional multiline padding
                                            },
                                        }]}
                                        inputProps={textFieldInputProps}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.created')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography >
                                        {moment.utc(activePart.created).tz(timezone).format('DD-MM-YYYY, HH:mm')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={rowStyle}>
                                <Grid item style={leftColumnStyle}>
                                    <Typography sx={labelStyle}>
                                        {t('label.updated')}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography >
                                        {moment.utc(activePart.updated).tz(timezone).format('DD-MM-YYYY, HH:mm')}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Form>
                    )}
                </Formik>
            </Box>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default TilePartInfo